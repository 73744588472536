import React from 'react'
import './theme.scss'
import { Link } from 'react-router-dom'

export function PrintButton({ label }) {
    return (
        <Link
            to="/consent-documents"
            className="button button--secondary button--print"
            target="_blank"
        >
            {label}
        </Link>
    )
}
