import PopUp from './PopUp'
import React from 'react'
import PrimaryButton from '../Buttons/PrimaryButton'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import warning from '../../assets/images/warning.svg'

export default function CancelNewCustomerPopup({ active, close, onYes }) {
    const content = (
        <div className="dialog__content-wrapper">
            <img className="dialog__warning-icon" src={warning} width={56} alt="warning" />
            <div className="dialog__content-title">
                If you exit the New Customer Creation wizard, all customer data will be lost.
                Proceed?
            </div>
        </div>
    )

    const actions = (
        <div className="dialog__buttons-wrapper">
            <PrimaryButton clickAction={onYes} label="Yes" />
            <SecondaryButton clickAction={close} label="No" />
        </div>
    )

    const config = {
        title: 'Cancel New Customer',
        type: 'CANCEL_NEW_USER',
        actions: actions,
        body: content,
    }
    return (
        <PopUp
            onClose={close}
            open={active}
            body={config.body}
            title={config.title}
            actions={config.actions}
        />
    )
}
