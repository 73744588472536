export function getCommissionNumberFromEmail(email, tasks) {
    const result = tasks.find((t) => t.email === email)
    if (result !== undefined) {
        return result.commissionNumber
    }
    return null
}

export function getCustomerFromEmail(email, tasks) {
    const result = tasks.find((t) => t.email === email)
    if (result !== undefined) {
        return result
    }
    return null
}

export function handleChangeDate(email, tasks) {
    const result = tasks.find((t) => t.email === email)
    if (result !== undefined) {
        return result
    }
    return null
}
