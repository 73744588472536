import React from 'react'
import { MuiThemeProvider } from 'material-ui'
import FormWrapper from '../../components/Registration/Panel/FormWrapper'
import info from '../../assets/images/info.svg'
import './theme.scss'
import Footer from '../../components/Footer/Footer'

export default function LogoutPage() {
    return (
        <MuiThemeProvider>
            <div className="logout-page__wrapper">
                <div className="logout-page__content">
                    <FormWrapper>
                        <div className="dialog__content-wrapper">
                            <div className="dialog__info-icon">
                                <img src={info} width={30} alt="info" />
                            </div>
                            <div className="dialog__content-title">
                                You have successfully logged out from the Connect Boarding
                                Application.
                            </div>
                            <div className="dialog__content-text">
                                To Log in again, you must do so via Group Retail Portal.
                            </div>
                        </div>
                    </FormWrapper>
                </div>
                <Footer />
            </div>
        </MuiThemeProvider>
    )
}
