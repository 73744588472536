import React, { useEffect } from 'react'
import { MuiThemeProvider } from 'material-ui'
import TextInput from '../../components/Form/TextInput'
import HorizontalStepper from '../../components/Registration/Stepper/HorizontalStepper'
import RegistrationNavigationButtons from '../../components/Registration/Navigation/RegistrationNavigationButtons'
import {
    INPUT_SHORT,
    NAVIGATION_BUTTONS_CONFIGURATION,
    ORDER_DETAILS_STEP
} from '../../constants/RegistrationConstants'
import { useDispatch, useSelector } from 'react-redux'
import FormWrapper from '../../components/Registration/Panel/FormWrapper'
import Panel from '../../components/Registration/Panel/Panel'
import './theme.scss'
import { useHistory } from 'react-router-dom'
import DatePicker from '../../components/Form/DatePicker'
import { OrderDetailsFormValidator } from './validators'

const NO_ERRORS = {
    commissionNumber: null,
    vin: null,
    oneOfDates: true,
}

export default function OrderDetailsForm() {
    const orderDetails = useSelector((state) => state.registration.orderDetails)
    const errors = useSelector((state) => state.registration.orderDetails.errors)

    const dispatch = useDispatch()
    let history = useHistory()

    const formId = 'order-details'
    const buttonConfig = NAVIGATION_BUTTONS_CONFIGURATION[ORDER_DETAILS_STEP]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const validate = () => {
        const validationErrors = OrderDetailsFormValidator(orderDetails)
        dispatch({
            type: 'SAVE_ORDER_DETAILS_ERRORS',
            payload: {
                value: validationErrors,
            },
        })
        return validationErrors
    }

    const handleChange = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_ORDER_DETAILS',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
    }
    const handleChangeVIN = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_ORDER_DETAILS',
            payload: {
                name: event.target.name,
                value: event.target.value.toUpperCase(),
            },
        })
    }
    const handleChangeProductionDate = (date) => {
        if (date instanceof Date) {
            dispatch({
                type: 'SAVE_ORDER_DETAILS',
                payload: {
                    name: 'plannedProductionDate',
                    value: date,
                },
            })
        }
    }

    const handleChangeHandoverDate = (date) => {
        if (date instanceof Date) {
            dispatch({
                type: 'SAVE_ORDER_DETAILS',
                payload: {
                    name: 'handoverDate',
                    value: date,
                },
            })
        }
    }

    const isFormValid = () => {
        const validationErrors = validate()
        return (
            NO_ERRORS.commissionNumber === validationErrors.commissionNumber &&
            NO_ERRORS.oneOfDates === validationErrors.oneOfDates &&
            NO_ERRORS.vin === validationErrors.vin
        )
    }

    const onNextClick = () => {
        if (isFormValid()) {
            history.push({
                pathname: buttonConfig.nextButtonUrl,
            })
        }
        dispatch({
            type: 'SET_ORDER_DETAILS_LIVE_VALIDATION',
        })
    }

    return (
        <MuiThemeProvider>
            <FormWrapper>
                <HorizontalStepper activeStep={ORDER_DETAILS_STEP} />
                <Panel>
                    <div className="registration-step-title">Order Details</div>
                    <form noValidate autoComplete="off">
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'commissionNumber'}
                                    label={`Commission Number`}
                                    value={orderDetails.commissionNumber}
                                    fullWidth
                                    error={errors.commissionNumber}
                                />
                            </div>
                        </div>
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'preferredServicePartner'}
                                    label={`Preferred Service Partner`}
                                    value={orderDetails.preferredServicePartner}
                                    fullWidth
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChangeVIN}
                                    inputId={'vin'}
                                    label={`VIN Number`}
                                    value={orderDetails.vin}
                                    fullWidth
                                    maxLength={INPUT_SHORT}
                                    error={errors.vin}
                                />
                            </div>
                        </div>
                        <div className="registration-form-row">
                            <div className="registration-step-field--datepicker">
                                <DatePicker
                                    formId={formId}
                                    inputId={'plannedProductionDate'}
                                    value={orderDetails.plannedProductionDate}
                                    handleChange={handleChangeProductionDate}
                                    label={`Planned Production Date`}
                                    error={errors.plannedProductionDate}
                                    oneOfDates={errors.oneOfDates}
                                    tooltipText={`The estimated date when the vehicle will be complete.`}
                                />
                            </div>
                            <div className="registration-step-field--datepicker">
                                <DatePicker
                                    formId={formId}
                                    inputId={'handoverDate'}
                                    value={orderDetails.handoverDate}
                                    handleChange={handleChangeHandoverDate}
                                    label={`Real Handover Date`}
                                    error={errors.handoverDate}
                                    oneOfDates={errors.oneOfDates}
                                    tooltipText={`This is the date you arrange with the customer for the car handover.`}
                                />
                            </div>
                        </div>
                    </form>
                    <RegistrationNavigationButtons
                        activeStep={ORDER_DETAILS_STEP}
                        onNextClick={onNextClick}
                    />
                </Panel>
            </FormWrapper>
        </MuiThemeProvider>
    )
}
