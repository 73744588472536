import TextInput from '../components/Form/TextInput'
import React, { Fragment, useCallback, useReducer } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from '../components/Form/DatePicker'
import PrimaryButton from '../components/Buttons/PrimaryButton'
import { CancelButton } from '../components/Buttons/CancelButton'

const Root = styled.div`
    padding: 0 20em;
`
const Row = styled.div`
    display: flex;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
`
const Cell = styled.div`
    margin-right: 16px;
    flex: 1 1 auto;
     {
        ${({ size }) =>
            size &&
            `
        flex: ${size} 1 auto;
    `}
    }
`

export default function OrderDetail() {
    const orderDetails = useSelector((state) => state.registration.orderDetails)
    const errors = useSelector((state) => state.registration.orderDetails.errors)

    const dispatch = useDispatch()

    const handleChange = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_ORDER_DETAILS',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
    }

    const handleForm = useCallback(
        (type) => (e) => {
            dispatch({ type: type, payload: e.target.value })
        },
        [dispatch]
    )

    const handleChangeDateOfBirth = (date) => {
        if (date instanceof Date) {
            dispatch({
                type: 'SAVE_ORDER_DETAILS',
                payload: {
                    name: 'plannedProductionName',
                    value: date,
                },
            })
        }
    }

    let preferedPartner = ['Partner 1', 'Partner 2']
    preferedPartner.unshift('Choose')

    return (
        <Root>
            <Row>
                <Cell size={3}>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'salesPerson'}
                        label={`Sales Person`}
                        value={orderDetails.salesPerson}
                        fullWidth
                        error={errors.salesPerson}
                    />
                </Cell>
                <Cell size={1}>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'commissionNumber'}
                        label={`Commission Number`}
                        value={orderDetails.commissionNumber}
                        fullWidth
                        error={errors.commissionNumber}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell size={3}>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'vinNumber'}
                        label={`VIN Number`}
                        value={orderDetails.vinNumber}
                        fullWidth
                        error={errors.vinNumber}
                    />
                </Cell>
                <Cell size={1}>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'fpin'}
                        label={`F-PIN`}
                        value={orderDetails.fpin}
                        fullWidth
                        error={errors.fpin}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell size={2}>
                    <DatePicker
                        inputId={'dateOfBirth'}
                        value={orderDetails.dateOfBirth}
                        handleChange={handleChangeDateOfBirth}
                        label={`Date of Birth`}
                        error={errors.dateOfBirth}
                    />
                </Cell>
                <Cell size={2}>
                    <DatePicker
                        inputId={'dateOfBirth'}
                        value={orderDetails.dateOfBirth}
                        handleChange={handleChangeDateOfBirth}
                        label={`Date of Birth`}
                        error={errors.dateOfBirth}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell size={1}>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'preferredServicePartner'}
                        label={'Prefered Service Partner'}
                        value={orderDetails.preferredServicePartner}
                        options={preferedPartner}
                        error={errors.preferredServicePartner}
                    />
                </Cell>
            </Row>
            <Row>
                <PrimaryButton label={'Update information'} />
            </Row>
            <Row>
                <CancelButton label={'Cancel order'} />
            </Row>
        </Root>
    )
}
