import { store } from '../index'
export const getDealerInfo = () => {
    // TODO add real API call
    store.dispatch({
        type: 'SET_DEALER_DATA_STARTED',
    })
    store.dispatch({
        type: 'SET_DEALER_DATA_SUCCESS',
        payload: {
            information: mockDealer,
        }, // TODO replace with response data once deployed
    })
}

const mockDealer = {
    id: 'Bruce Wayne',
    firstName: 'Bruce',
    lastName: 'Wayne',
    country: 'CZ',
    dealershipId: '222',
}
