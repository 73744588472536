import PopUp from './PopUp'
import React, { useCallback, useEffect, useState } from 'react'
import PrimaryButton from '../Buttons/PrimaryButton'
import { validateEmailCreateNewCustomerPopup } from '../../pages/Registration/validators'
import TextInput from '../Form/TextInput'
import { checkCustomer, getCustomerGroupIdData } from '../../actions/customerActions'
import { useSelector } from 'react-redux'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import { CancelButton } from '../Buttons/CancelButton'
import warning from '../../assets/images/warning.svg'
import { getCommissionNumberFromEmail, getCustomerFromEmail } from '../../util/util'
import { useHistory } from 'react-router'

export default function CreateNewCustomerPopup({ active, close, onConfirmNewUser }) {
    const [emailValue, setEmailValue] = useState('')
    const [emailError, setEmailError] = useState(null)
    const [emailLiveValidation, setEmailLiveValidation] = useState(false)
    const customerCheck = useSelector((state) => state.customer.customerCheck)
    const tasks = useSelector((state) => state.tasks.taskList)
    const [userExistsInCBA, setUserExistsInCBA] = useState(false)
    const [userExistsInGID, setUserExistsInGID] = useState(false)
    const [newUser, setNewUser] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setEmailValue('')
        setEmailError(null)
        setEmailLiveValidation(false)
        setUserExistsInCBA(false)
        setUserExistsInGID(false)
        setNewUser(false)
    }, [active])

    useEffect(() => {
        if (customerCheck !== null) {
            if (customerCheck.cbaAccount) {
                setUserExistsInCBA(true)
            } else if (customerCheck.groupIdAccount) {
                setUserExistsInGID(true)
            } else {
                setNewUser(true)
                if (active) {
                    onConfirmNewUser(emailValue)
                }
            }
        }
    }, [active, customerCheck, emailValue, onConfirmNewUser])

    const changeValue = useCallback(
        (e) => {
            setEmailValue(e.target.value)
            if (emailLiveValidation) {
                setEmailError(validateEmailCreateNewCustomerPopup(e.target.value))
            }
        },
        [emailLiveValidation]
    )

    const onCreateClick = useCallback(
        (e) => {
            let error = validateEmailCreateNewCustomerPopup(emailValue)
            if (error === null) {
                checkCustomer(emailValue)
            } else {
                setEmailError(error)
                setEmailLiveValidation(true)
            }
        },
        [emailValue]
    )

    const handleViewDetail = () => {
        const commissionNumber = getCommissionNumberFromEmail(emailValue, tasks)
        const customer = getCustomerFromEmail(emailValue, tasks)
        close()
        history.push({
            pathname: '/customers/' + commissionNumber,
            state: { customer: customer },
        })
    }

    const handleProceed = () => {
        getCustomerGroupIdData(emailValue)
        close()
        history.push({
            pathname: '/customer-information',
        })
    }

    const createNewUserContent = (
        <TextInput
            inputId={'email'}
            label={'Email'}
            fullWidth={true}
            value={emailValue}
            handleChange={changeValue}
            error={emailError}
            noCheckmark={emailError === null && !emailLiveValidation}
        />
    )

    const userExistsInCBAContent = (
        <div className="dialog__content-wrapper">
            <img className="dialog__warning-icon" src={warning} width={56} alt="warning" />
            <div className="dialog__content-title">
                The Customer already exists in the Connect Boarding Application.
            </div>
        </div>
    )

    const userExistsInGIDContent = (
        <div className="dialog__content-wrapper">
            <img className="dialog__warning-icon" src={warning} width={56} alt="warning" />
            <div className="dialog__content-title">
                This email address is already registered with a GroupID account.
            </div>
            <div className="dialog__content-text">
                The next page will be populated with personal data from GroupID. You must ensure the
                Customer's identity matches the profile data from GroupID. Disclosing personal data
                from GroupID to an unauthorised third party may breach GDPR.
            </div>
        </div>
    )

    const enterEmailActions = (
        <PrimaryButton label={'Create new customer'} clickAction={onCreateClick} />
    )

    const userExistsInCBAActions = (
        <div className="dialog__buttons-wrapper">
            <SecondaryButton clickAction={handleViewDetail} label="View Customer" />
            <CancelButton clickAction={close} label="Cancel" />
        </div>
    )

    const userExistsInGIDActions = (
        <div className="dialog__buttons-wrapper">
            <SecondaryButton clickAction={handleProceed} label="Proceed" />
            <CancelButton clickAction={close} label="Cancel" />
        </div>
    )

    const userExistsInCBAPopupData = {
        title: 'Create New Customer',
        type: 'CREATE_NEW_USER_EXISTS_IN_CBA',
        actions: userExistsInCBAActions,
        body: userExistsInCBAContent,
    }

    const userExistsInGIDPopupData = {
        title: 'Create New Customer',
        type: 'CREATE_NEW_USER_EXISTS_IN_GID',
        actions: userExistsInGIDActions,
        body: userExistsInGIDContent,
    }

    const enterEmailPopupData = {
        title: 'Create New Customer',
        type: 'CREATE_NEW_USER',
        actions: enterEmailActions,
        body: createNewUserContent,
    }

    const showUserExistsInCBA = () => {
        return (
            <PopUp
                onClose={close}
                open={active}
                body={userExistsInCBAPopupData.body}
                title={userExistsInCBAPopupData.title}
                actions={userExistsInCBAPopupData.actions}
            />
        )
    }

    const showUserExistsInGID = () => {
        return (
            <PopUp
                onClose={close}
                open={active}
                body={userExistsInGIDPopupData.body}
                title={userExistsInGIDPopupData.title}
                actions={userExistsInGIDPopupData.actions}
            />
        )
    }

    if (!active) {
        return null
    } else if (userExistsInCBA) {
        return showUserExistsInCBA()
    } else if (userExistsInGID) {
        return showUserExistsInGID()
    } else if (newUser) {
        return null
    }

    return (
        <PopUp
            onClose={close}
            open={active}
            body={enterEmailPopupData.body}
            title={enterEmailPopupData.title}
            actions={enterEmailPopupData.actions}
        />
    )
}
