import React from 'react'
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar'
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
})

const DatePickerToolbar = function(props) {
    const { date, isLandscape, openView, setOpenView, title } = props

    const handleChangeViewClick = (view) => (e) => {
        setOpenView(view)
    }

    const classes = useStyles()

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]

    return (
        <PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
            <ToolbarButton
                onClick={handleChangeViewClick('month')}
                variant="h6"
                label={months[date.getMonth()] + ' ' + date.getFullYear().toString()}
                selected={openView === 'month'}
            />
        </PickerToolbar>
    )
}

export default DatePickerToolbar
