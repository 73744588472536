import React, { Fragment, useCallback, useState } from 'react'
import styled from 'styled-components'
import InputTooltip from '../Tooltip/InputTooltip'

const NavigationWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 30px;
`

const TabItem = styled.div`
    font-size: 14px;
    display: flex;
    letter-spacing: 1px;
    font-weight: bold;
    color: #808285;
    text-transform: uppercase;
    margin-right: 50px;
    text-align: center;
    cursor: pointer;
    position: relative;
     {
        ${({ active }) =>
            active &&
            `
	    color: black;
    `}
    }
    &:after {
        ${({ active }) =>
            active &&
            `
	    content: '';
	    background-color: #4ba82e;
	    position: absolute;
	    bottom: -8px;
	    left:0;
	    width: 100%;
	    height: 3px;
    `}
    }
`

const TabToolTip = styled.div`
    position: absolute;
    top: -7px;
    right: -27px;
`

export default function TabNavigation({ tabs, handleActiveTab, onTabClick }) {
    const [activeTab, setActiveTab] = useState(0)

    const clickOnTab = useCallback(
        (index, tab) => (e) => {
            // TODO: Put request call to backend to get filtered data for show
            setActiveTab(index)
            handleActiveTab && handleActiveTab(index)
            onTabClick && onTabClick(tab.label)
        },
        [handleActiveTab, onTabClick]
    )

    return (
        <NavigationWrapper>
            {tabs.map((tab, index) => (
                <Fragment key={`${index}-tab`}>
                    <TabItem
                        onClick={clickOnTab(index, tab)}
                        active={index === activeTab ? true : false}
                    >
                        {tab.label}
                        <TabToolTip>
                            {tab.tooltip && <InputTooltip text={tab.tooltip} />}
                        </TabToolTip>
                    </TabItem>
                </Fragment>
            ))}
        </NavigationWrapper>
    )
}
