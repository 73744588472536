import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { registrationProcessReducer } from './reducers/registrationProcessReducer'
import { taskReducer } from './reducers/taskReducer'
import { customerReducer } from './reducers/customerReducer'
import { dealerReducer } from './reducers/dealerReducer'
import { configurationReducer } from './reducers/configurationReducer'
import { popupsReducer } from './reducers/popupsReducer'

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        configuration: configurationReducer,
        registration: registrationProcessReducer,
        dealer: dealerReducer,
        tasks: taskReducer,
        customer: customerReducer,
        popups: popupsReducer,
    })
