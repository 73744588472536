import React, { useCallback, useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import Page from '../../containers/Page'
import TabNavigation from '../../components/TabNavigation/TabNavigation'
import Card, { CardTitle } from '../../components/Card'
import CustomerJourney from '../../components/CustomerJourney'
import OrderDetail from '../../containers/OrderDetail'
import SkodaIdent from '../../containers/SkodaIdent'
import CustomerInformation from '../../containers/CustomerInformation'

const MediumWidthCard = styled(Card)`
    max-width: 1000px;
`

function CustomerDetail({ location }) {
    const [content, setContent] = useState(null)
    const [activeTab, setActiveTab] = useState(3)

    const tabs = [
        { label: 'Customer Journey' },
        { label: 'Order details' },
        { label: 'Škoda ident' },
        { label: 'Customer information' },
    ]

    useEffect(() => {
        switch (activeTab) {
            case 0:
                setContent(<CustomerJourney />)
                break
            case 1:
                setContent(<OrderDetail />)
                break
            case 2:
                setContent(<SkodaIdent />)
                break
            case 3:
                setContent(<CustomerInformation />)
                break
            default:
                setContent(<div>loading...</div>)
        }
    }, [activeTab, setActiveTab])

    const handleTabContent = useCallback((index) => {
        setActiveTab(index)
    }, [])

    return (
        <Page
            withBackLink={true}
            backLinkUrl={'/customers'}
            title={`${location.state.customer.lastName} ${location.state.customer.firstName}`}
        >
            <TabNavigation tabs={tabs} handleActiveTab={handleTabContent} />
            <MediumWidthCard>
                <CardTitle>{tabs[activeTab].label}</CardTitle>
                {content}
            </MediumWidthCard>
        </Page>
    )
}

export default CustomerDetail
