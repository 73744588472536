import React, { useEffect } from 'react'
import { MuiThemeProvider } from 'material-ui'
import TextInput from '../../components/Form/TextInput'
import HorizontalStepper from '../../components/Registration/Stepper/HorizontalStepper'
import RegistrationNavigationButtons from '../../components/Registration/Navigation/RegistrationNavigationButtons'
import {
    INPUT_MEDIUM,
    NAVIGATION_BUTTONS_CONFIGURATION,
    SKODA_IDENT_STEP,
} from '../../constants/RegistrationConstants'
import { useDispatch, useSelector } from 'react-redux'
import FormWrapper from '../../components/Registration/Panel/FormWrapper'
import Panel from '../../components/Registration/Panel/Panel'
import './theme.scss'
import { useHistory } from 'react-router-dom'
import DatePicker from '../../components/Form/DatePicker'
import { SkodaIdentFormValidator } from './validators'
import { countriesList } from '../../configuration/CountriesAddressBuilderConfiguration'

const NO_ERRORS = {
    licenceNumber: null,
    issueDate: null,
    expirationDate: null,
    countryOfIssue: null,
    dateOfBirth: null,
}

export default function SkodaIdentForm() {
    const skodaIdent = useSelector((state) => state.registration.skodaIdent)
    const errors = useSelector((state) => state.registration.skodaIdent.errors)

    const dispatch = useDispatch()
    let history = useHistory()

    const formId = 'skoda-ident'
    const buttonConfig = NAVIGATION_BUTTONS_CONFIGURATION[SKODA_IDENT_STEP]
    let countryOfIssueOptions = countriesList.map((c) => c.name)
    countryOfIssueOptions.unshift('Choose')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const validate = () => {
        const validationErrors = SkodaIdentFormValidator(skodaIdent)
        dispatch({
            type: 'SAVE_SKODA_IDENT_ERRORS',
            payload: {
                value: validationErrors,
            },
        })
        return validationErrors
    }

    const handleChange = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_SKODA_IDENT',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
    }
    const handleChangeIssueDate = (date) => {
        if (date instanceof Date) {
            dispatch({
                type: 'SAVE_SKODA_IDENT',
                payload: {
                    name: 'issueDate',
                    value: date,
                },
            })
        }
    }

    const handleChangeExpirationDate = (date) => {
        if (date instanceof Date) {
            dispatch({
                type: 'SAVE_SKODA_IDENT',
                payload: {
                    name: 'expirationDate',
                    value: date,
                },
            })
        }
    }

    const handleChangeDateOfBirth = (date) => {
        if (date instanceof Date) {
            dispatch({
                type: 'SAVE_SKODA_IDENT',
                payload: {
                    name: 'dateOfBirth',
                    value: date,
                },
            })
        }
    }
    const isFormValid = () => {
        const validationErrors = validate()
        return JSON.stringify(NO_ERRORS) === JSON.stringify(validationErrors)
    }

    const onNextClick = () => {
        if (isFormValid()) {
            dispatch({
                type: 'REMOVE_SKIPPED_STEP',
                payload: {
                    value: SKODA_IDENT_STEP,
                },
            })
            history.push({
                pathname: buttonConfig.nextButtonUrl,
            })
        }
        dispatch({
            type: 'SET_SKODA_IDENT_LIVE_VALIDATION',
        })
    }

    return (
        <MuiThemeProvider>
            <FormWrapper>
                <HorizontalStepper activeStep={SKODA_IDENT_STEP} />
                <Panel>
                    <div className="registration-step-title">ŠKODA Ident</div>
                    <form noValidate autoComplete="off">
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'licenceNumber'}
                                    label={`Driving Licence Number`}
                                    value={skodaIdent.licenceNumber}
                                    fullWidth
                                    error={errors.licenceNumber}
                                    maxLength={INPUT_MEDIUM}
                                />
                            </div>
                        </div>
                        <div className="registration-form-row">
                            <div className="registration-step-field--datepicker">
                                <DatePicker
                                    formId={formId}
                                    inputId={'issueDate'}
                                    value={skodaIdent.issueDate}
                                    handleChange={handleChangeIssueDate}
                                    label={`Date of Issue`}
                                    error={errors.issueDate}
                                />
                            </div>
                            <div className="registration-step-field--datepicker">
                                <DatePicker
                                    formId={formId}
                                    inputId={'expirationDate'}
                                    value={skodaIdent.expirationDate}
                                    handleChange={handleChangeExpirationDate}
                                    label={`Date of Expiry`}
                                    error={errors.expirationDate}
                                />
                            </div>
                        </div>
                        <div className="registration-step-field">
                            <TextInput
                                formId={formId}
                                handleChange={handleChange}
                                inputId={'countryOfIssue'}
                                label={'Country of Issue'}
                                value={skodaIdent.countryOfIssue}
                                options={countryOfIssueOptions}
                                error={errors.countryOfIssue}
                            />
                        </div>
                        <div className="registration-step-field--datepicker">
                            <DatePicker
                                formId={formId}
                                inputId={'dateOfBirth'}
                                value={skodaIdent.dateOfBirth}
                                handleChange={handleChangeDateOfBirth}
                                label={`Date of Birth`}
                                error={errors.dateOfBirth}
                            />
                        </div>
                    </form>
                    <RegistrationNavigationButtons
                        activeStep={SKODA_IDENT_STEP}
                        onNextClick={onNextClick}
                    />
                </Panel>
            </FormWrapper>
        </MuiThemeProvider>
    )
}
