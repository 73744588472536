import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import './theme.scss'
import { useSelector } from 'react-redux'

function getStepLabels() {
    return ['Customer Information', 'ŠKODA Ident', 'Order Details', 'Consent Verification']
}

export default function HorizontalStepper({ activeStep }) {
    const skippedSteps = useSelector((state) => state.registration.skippedSteps)

    const stepLabels = getStepLabels()

    const isStepSkipped = (step) => {
        return skippedSteps.find((s) => s === step)
    }
    return (
        <div className="horizontal-stepper">
            <Stepper activeStep={activeStep} alternativeLabel style={{ background: 'none' }}>
                {stepLabels.map((label, index) => {
                    const stepProps = {}
                    if (isStepSkipped(index)) {
                        stepProps.completed = false
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </div>
    )
}
