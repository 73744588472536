import React from 'react'
import styled from 'styled-components'
import checkmark from '../assets/images/checkmark.svg'

const ListWrapper = styled.div`
    margin-top: 36px;
    padding: 0px 64px;
`
const List = styled.div`
    border-top: 1px solid #f3f3f3;
`
const Row = styled.div`
    display: flex;
    padding: 30px 0;
    border-bottom: 1px solid #f3f3f3;
     {
        ${({ completedClass }) =>
            completedClass &&
            `
        color: #c0c0c0;
        content: '';
    `}
    }
`

const StatusText = styled.div`
    flex: 1 0 auto;
`
const Checkmark = styled.div`
    flex: 0 1 auto;
`

export default function CustomerJourney(status) {
    const statuses = [
        "Complete user's profile",
        'Fill VIN and handover date',
        'Identity Kit registration necessary',
        'The mobile application wasn’t install',
        "Connect VIN and customer wasn't established",
        'Registration of connect services incomplete',
        'Fill pairing code in the car',
        'ŠKODA Ident process incomplete',
        'Preferred service partner incomplete',
        'DMS incomplete',
        'Process complete',
    ]

    const list = statuses.map((statusRow, index) => {
        let completedClass = index < 3 ? true : false
        return (
            <Row key={statusRow} completedClass={completedClass}>
                <StatusText>
                    {index + 1}. {statusRow}
                </StatusText>
                {completedClass && (
                    <Checkmark>
                        <img src={checkmark} />
                    </Checkmark>
                )}
            </Row>
        )
    })

    return (
        <ListWrapper>
            <List>{list}</List>
        </ListWrapper>
    )
}
