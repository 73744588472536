import React from 'react'
import './theme.scss'

export default function Toast({ active, close, text }) {
    if (!active) {
        return null
    }
    return (
        <div className="toast">
            <div className="toast__content">
                <div className="toast__text">{text}</div>
                <div className={'toast__cross-icon'} onClick={close} />
            </div>
        </div>
    )
}
