import React, { useState, Fragment } from 'react'
import './index.scss'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Header from './components/Header/Header'
import muiTheme from './muiTheme'
import { StylesProvider, MuiThemeProvider } from '@material-ui/core'
import CustomerInformationForm from './pages/Registration/CustomerInformationForm'
import SkodaIdentForm from './pages/Registration/SkodaIdentForm'
import OrderDetailsForm from './pages/Registration/OrderDetailsForm'
import ConsentVerificationForm from './pages/Registration/ConsentVerificationForm'
import ConsentDocumentPreview from './pages/Documents/ConsentDocumentPreview'
import CustomerList from './pages/Customers/CustomerList'
import CustomerDetail from './pages/Customers/CustomerDetail'
import Footer from './components/Footer/Footer'
import LogoutPage from './pages/Static/LogoutPage'

function App() {
    const [isAuthenticated, setAuthenticated] = useState(true)

    return (
        <Router>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={muiTheme}>
                    <Switch>
                        <Route path="/consent-documents">
                            <ConsentDocumentPreview />
                        </Route>
                        <Route path="/logout">
                            <LogoutPage />
                        </Route>
                        <Fragment>
                            <Header />
                            <Route path="/login">
                                <Login fakeLoging={setAuthenticated} />
                            </Route>
                            <Route path="/customer-information">
                                <CustomerInformationForm />
                            </Route>
                            <Route path="/skoda-ident">
                                <SkodaIdentForm />
                            </Route>
                            <Route path="/order-details">
                                <OrderDetailsForm />
                            </Route>
                            <Route path="/consent-verification">
                                <ConsentVerificationForm />
                            </Route>
                            <ProtectedRoute path="/customers" exact isAuth={isAuthenticated}>
                                <CustomerList title={'Dealership Customers'} />
                            </ProtectedRoute>
                            <ProtectedRoute
                                path="/customers/:commissionNumber"
                                exact
                                isAuth={isAuthenticated}
                                component={CustomerDetail}
                            />
                            <ProtectedRoute exact path="/" isAuth={isAuthenticated}>
                                <Dashboard />
                            </ProtectedRoute>
                            <Footer />
                        </Fragment>
                    </Switch>
                </MuiThemeProvider>
            </StylesProvider>
        </Router>
    )
}

function ProtectedRoute({ isAuth, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

export default App
