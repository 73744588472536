import React, { useEffect, useState } from 'react'
import { getCultures, getTranslations } from '../../actions/configurationActions'
import './theme.scss'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import beak from '../../assets/images/beak-down.svg'
import InputTooltip from '../Tooltip/InputTooltip'
import { useTranslation } from 'react-i18next'

export function SelectIconFooter() {
    return (
        <Icon className="text-input-select-icon__footer">
            <img src={beak} alt="beak" width={16} />
        </Icon>
    )
}

export default function LanguageSelector() {
    const information = useSelector((state) => state.dealer.information)
    const selectedLanguage = useSelector((state) => state.configuration.selectedLanguage)
    const countries = useSelector((state) => state.configuration.countries)
    const loading = useSelector((state) => state.configuration.loading)
    const dispatch = useDispatch()
    const [languages, setLanguages] = useState([])
    const [selectedCountry, setSelectedCountry] = useState(null)
    const { t, i18n } = useTranslation('cba')

    useEffect(() => {
        getCultures()
    }, [])

    useEffect(() => {
        if (countries !== null && information.country !== null && countries.length > 0) {
            const selectedCulture = countries.find((c) => c.countryCode === information.country)
            setSelectedCountry(selectedCulture.name)
            setLanguages(selectedCulture.languages)
            i18n.changeLanguage(selectedCulture.languages[0])
            dispatch({
                type: 'SET_SELECTED_LANGUAGE',
                payload: {
                    value: selectedCulture.languages[0],
                },
            })
            getTranslations(selectedCulture.languages)
        }
    }, [dispatch, information, countries, loading, i18n])

    const useStyles = makeStyles((t) => ({
        formControl: {
            margin: t.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
    }))

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                // backgroundColor: '#373737',
                // color: '#fff',
            },
        },
    }

    function getStyles(name, languageName, t) {
        return {
            fontWeight:
                languageName.indexOf(name) === -1
                    ? t.typography.fontWeightRegular
                    : t.typography.fontWeightMedium,
        }
    }

    const classes = useStyles()
    const theme = useTheme()

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value)
        dispatch({
            type: 'SET_SELECTED_LANGUAGE',
            payload: {
                value: event.target.value,
            },
        })
    }

    if (!countries || !selectedLanguage) {
        return null
    }

    return (
        <div className="language-selector-wrapper">
            <div className={classes.formControl + ' language-selector__country'}>
                <div className="language-selector__country-name">{selectedCountry}</div>
                <InputTooltip text={'The country of your dealership. This cannot be changed.'} />
            </div>
            <FormControl className={classes.formControl} variant={'standard'}>
                <Select
                    displayEmpty
                    value={selectedLanguage}
                    onChange={handleChange}
                    input={<Input disableUnderline style={{ color: '#fff' }} />}
                    MenuProps={MenuProps}
                    IconComponent={SelectIconFooter}
                >
                    <MenuItem className="language-selector-title" value="">
                        <span>SELECT LANGUAGE</span>
                    </MenuItem>
                    {languages.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, selectedLanguage, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
