import TextInput from '../components/Form/TextInput'
import React, { Fragment, useCallback, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from '../components/Form/DatePicker'
import PrimaryButton from '../components/Buttons/PrimaryButton'
import { countriesList } from '../configuration/CountriesAddressBuilderConfiguration'
import { SkodaIdentFormValidator } from '../pages/Registration/validators'

const NO_ERRORS = {
    licenceNumber: null,
    issueDate: null,
    expirationDate: null,
    countryOfIssue: null,
    dateOfBirth: null,
}

const Root = styled.div`
    padding: 0 20em;
`
const Row = styled.div`
    display: flex;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
`
const Cell = styled.div`
    margin-right: 16px;
    flex: 1 1 auto;
     {
        ${({ size }) =>
            size &&
            `
        flex: ${size} 1 auto;
    `}
    }
`

export default function SkodaIdent() {
    const skodaIdent = useSelector((state) => state.registration.skodaIdent)
    const errors = useSelector((state) => state.registration.skodaIdent.errors)
    const customerCountry = useSelector((state) => state.registration.customerInformation.country)

    const dispatch = useDispatch()

    const handleChange = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_SKODA_IDENT',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
    }

    const handleForm = useCallback(
        (type) => (e) => {
            dispatch({ type: type, payload: e.target.value })
        },
        [dispatch]
    )

    const handleChangeDate = useCallback(
        (key) => (event) => {
            if (event instanceof Date) {
                dispatch({
                    type: 'SAVE_SKODA_IDENT',
                    payload: {
                        name: key,
                        value: event,
                    },
                })
            }
        },
        [dispatch]
    )

    let countryOfIssueOptions = countriesList.map((c) => c.name)
    countryOfIssueOptions.unshift('Choose')

    return (
        <Root>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'driverLicenseNumber'}
                        label={`Driver License Number`}
                        value={skodaIdent.driverLicenseNumber}
                        fullWidth
                        error={errors.driverLicenseNumber}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell size={2}>
                    <DatePicker
                        inputId={'issueDate'}
                        value={skodaIdent.issueDate}
                        handleChange={handleChangeDate('issueDate')}
                        label={`Issue Date`}
                        error={errors.issueDate}
                    />
                </Cell>
                <Cell size={2}>
                    <DatePicker
                        inputId={'expirationDate'}
                        value={skodaIdent.expirationDate}
                        handleChange={handleChangeDate('expirationDate')}
                        label={`Expiration Date`}
                        error={errors.expirationDate}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell size={1}>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'countryOfIssue'}
                        label={'Country of issue'}
                        value={customerCountry.id || skodaIdent.countryOfIssue}
                        options={countryOfIssueOptions}
                        error={errors.countryOfIssue}
                    />
                </Cell>
            </Row>
            <Cell>
                <DatePicker
                    inputId={'dateOfBirth'}
                    value={skodaIdent.dateOfBirth}
                    handleChange={handleChangeDate('dateOfBirth')}
                    label={`Date of birth`}
                    error={errors.dateOfBirth}
                />
            </Cell>
            <Row>
                <PrimaryButton label={'Update information'} />
            </Row>
        </Root>
    )
}
