import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import SortingTable from '../../components/SortingTable/SortingTable'
import TabNavigation from '../../components/TabNavigation/TabNavigation'
import Page from '../../containers/Page'
import FilterPopup from '../../components/FilterPopup/FilterPopup'
import { useSelector } from 'react-redux'
import { DateTime, Interval, Duration } from 'luxon'
import { getConfiguration } from '../../actions/configurationActions'
import { getDealerInfo } from '../../actions/dealerActions'
import Toast from '../../components/Toast/Toast'
import { closeToast } from '../../actions/popupsActions'
import Icon from '../../components/Icon'
import Arrow from '../../assets/images/icon-arrow-right-light.svg'

const FilterRow = styled.div`
    display: flex;
    position: relative;
`

const Filter = styled.div`
    display: flex;
    color: #4b4a4b;
    cursor: pointer;
    font-size: 14px;
`
const ArrowGreenDown = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transform: rotate(90deg);
    transition: 0.3s;
    margin-top: -2px;
     {
        ${({ actived }) =>
            actived &&
            `
            transform: rotate(-90deg);
        `}
    }
    img {
        width: 100%;
    }
`

function CustomerList({ title }) {
    const [openFilter, setOpenFilter] = useState(false)
    const customers = useSelector((state) => state.tasks.taskList)
    const toastActive = useSelector((state) => state.popups.toastActive)
    const [tableData, setTableData] = useState(customers)
    const [activeTab, setActiveTab] = useState('All customers')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setTableData(customers)
    }, [customers])

    useEffect(() => {
        getConfiguration()
        getDealerInfo()
    }, [])

    useEffect(() => {
        let monthInFuture = Interval.after(DateTime.utc(), Duration.fromISO('P1M'))
        let twoDaysInFuture = Interval.after(DateTime.utc(), Duration.fromISO('P2D'))
        const filteredData = customers.filter((item) => {
            switch (activeTab) {
                case 'All customers':
                    return item
                case 'Vin & handover date':
                    return (
                        // item.dateOfCreation > monthInFuture.end &&
                        item.vin === null || item.handoverDate === null
                    )
                case 'Pairing code':
                    return (
                        // item.handoverDate < twoDaysInFuture.end &&
                        !item.fpin
                    )
                case 'Car handover':
                    return item.handoverDate < twoDaysInFuture.end
                case 'Process incomplete':
                    return item.customerStatus !== 'registrationProcessComplete'
            }
        })
        setTableData(filteredData)
    }, [activeTab, customers])

    const onShowFilter = useCallback(() => {
        setOpenFilter(!openFilter)
        oncloseToast()
    }, [openFilter])

    const FilterByTab = useCallback(
        (tab) => {
            setActiveTab(tab)
            oncloseToast()
        },
        [setActiveTab]
    )

    const oncloseToast = () => {
        closeToast()
    }

    return (
        <>
            <Toast
                text="New customer successfully created"
                close={oncloseToast}
                active={toastActive}
            />
            <Page title={title}>
                <FilterRow>
                    <TabNavigation
                        onTabClick={FilterByTab}
                        tabs={[
                            {
                                label: 'All customers',
                                tooltip:
                                    'All customers within your dealership are displayed. Default sorting is by handover date (closest date first) then date created (oldest date first). You can click a column to reorder the table based on the selected column or you can use the filter button to view customers based on their status or date created. You can use the tabs (VIN & Handover date, Pairing Code, Car Handover, Process incomplete) to view customers who need your attention.',
                            },
                            {
                                label: 'Vin & handover date',
                                tooltip:
                                    'When selected, customers are displayed who have their planned production date in less than one month and which are either missing a handover date or a VIN. Please complete both fields to continue the registration process.',
                            },
                            {
                                label: 'Pairing code',
                                tooltip:
                                    'When selected, customers are displayed who have chosen to complete car enrollment at the dealer and who have shared their Pairing Code with you. Please input the Pairing Code into the customer’s vehicle before handover.',
                            },
                            {
                                label: 'Car handover',
                                tooltip:
                                    'When selected, customers are displayed who have their car handover (Real handover date) today.',
                            },
                            {
                                label: 'Process incomplete',
                                tooltip:
                                    "When selected, customers are displayed who have their car's handover date in the past but who have not chosen a Preferred Service Partner, set Service Scheduling or performed all actions in the registration process. You can contact the customer to request them to complete this. Select a customer to see which actions still need to be completed.",
                            },
                        ]}
                    />
                    {activeTab === 'All customers' && (
                        <Filter onClick={onShowFilter}>
                            Filter
                            <ArrowGreenDown actived={openFilter}>
                                <img src={Arrow} />
                            </ArrowGreenDown>
                        </Filter>
                    )}
                    {openFilter && <FilterPopup />}
                </FilterRow>
                <SortingTable data={tableData} />
            </Page>
        </>
    )
}

export default CustomerList
