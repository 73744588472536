import { store } from '../index'
import { I18n } from '../api'
import i18next from 'i18next'

const initI18n = () => {
    const translations = store.getState().configuration.translations
    let resources = {}
    Object.entries(translations).forEach(([k, v]) => {
        resources[k] = {
            translation: v,
        }
        if (i18next.getResourceBundle(k, 'cba') === undefined) {
            i18next.addResourceBundle(k, 'cba', v)
        }
    })
}

export const getCultures = () => {
    const cultures = store.getState().configuration.cultures
    if (cultures.length === 0) {
        store.dispatch({
            type: 'GET_CULTURES_STARTED',
        })
        I18n.getCultures()
            .then((response) => {
                store.dispatch({
                    type: 'GET_CULTURES_SUCCESS',
                    payload: {
                        value: response.body,
                    },
                })
            })
            .catch((error) => {
                store.dispatch({
                    type: 'GET_CULTURES_ERROR',
                })
            })
    }
}

export const getConfiguration = () => {
    // TODO add real API call
    store.dispatch({
        type: 'GET_CONFIG_STARTED',
    })
    store.dispatch({
        type: 'GET_CONFIG_SUCCESS',
        payload: {
            config: mockConfig,
        }, // TODO replace with response data once deployed
    })
}

export const getTranslations = (languages) => {
    const translations = store.getState().configuration.translations
    const countries = store.getState().configuration.countries
    const information = store.getState().dealer.information
    const selectedLanguages = countries.find((c) => c.countryCode === information.country).languages

    let loaded = true
    selectedLanguages.forEach((l) => {
        if (translations[l] === null || translations[l] === undefined) {
            loaded = false
        }
    })
    if (loaded && translations.en) {
        initI18n()
    } else {
        store.dispatch({
            type: 'GET_TRANSLATIONS_STARTED',
        })
        Promise.all(languages.map((l) => I18n.getCultureTranslations(l)))
            .then((responses) => {
                responses.forEach((r, i) => {
                    if (r.body === null) {
                        // TODO shit solution, needs fixing
                        getTranslations(languages)
                    } else {
                        store.dispatch({
                            type: 'GET_TRANSLATIONS_SUCCESS',
                            payload: {
                                language: languages[i],
                                translations: r.body,
                            },
                        })
                    }
                })
                store.dispatch({
                    type: 'GET_ALL_TRANSLATIONS_SUCCESS',
                })
                initI18n()
            })
            .catch((error) => {
                console.log(error)
                // TODO shit solution, needs fixing
                getTranslations(languages)

            })
    }
}

const mockConfig = {
    salutations: [
        {
            id: '1',
            name: 'Mrs.',
        },
        {
            id: '2',
            name: 'Ms.',
        },
        {
            id: '3',
            name: 'Mr.',
        },
    ],
    addressTypes: [
        {
            id: '1',
            type: 'Home',
        },
        {
            id: '2',
            type: 'Business',
        },
        {
            id: '3',
            type: 'Billing',
        },
    ],
    countries: [
        {
            id: '1',
            countryCode: 'AT',
            name: 'Austria',
            dialCode: '+43',
            languages: ['de', 'en'],
            defaultLanguage: 'de',
        },
        {
            id: '2',
            countryCode: 'BE',
            name: 'Belgium',
            dialCode: '+32',
            languages: ['fr', 'nl', 'en'],
            defaultLanguage: 'fr',
        },
        {
            id: '3',
            countryCode: 'BA',
            name: 'Bosnia and Herzegovina',
            dialCode: '+387',
            languages: ['bs', 'en'],
            defaultLanguage: 'bs',
        },
        {
            id: '4',
            countryCode: 'BG',
            name: 'Bulgaria',
            dialCode: '+359',
            languages: ['bg', 'en'],
            defaultLanguage: 'bg',
        },
        {
            id: '5',
            countryCode: 'IC',
            name: 'Canary Islands',
            dialCode: '',
            languages: ['es', 'en'],
            defaultLanguage: 'es',
        },
        {
            id: '6',
            countryCode: 'HR',
            name: 'Croatia',
            dialCode: '+385',
            languages: ['hr', 'en'],
            defaultLanguage: 'hr',
        },
        {
            id: '7',
            countryCode: 'CY',
            name: 'Cyprus',
            dialCode: '+357',
            languages: ['el', 'en'],
            defaultLanguage: 'el',
        },
        {
            id: '8',
            countryCode: 'CZ',
            name: 'Czech Republic',
            dialCode: '+420',
            languages: ['cs', 'en'],
            defaultLanguage: 'cs',
        },
        {
            id: '9',
            countryCode: 'DK',
            name: 'Denmark',
            dialCode: '+45',
            languages: ['da', 'en'],
            defaultLanguage: 'da',
        },
        {
            id: '10',
            countryCode: 'EE',
            name: 'Estonia',
            dialCode: '+372',
            languages: ['et', 'ru', 'en'],
            defaultLanguage: 'et',
        },
        {
            id: '11',
            countryCode: 'FI',
            name: 'Finland',
            dialCode: '+358',
            languages: ['fi', 'en'],
            defaultLanguage: 'fi',
        },
        {
            id: '12',
            countryCode: 'FR',
            name: 'France',
            dialCode: '+33',
            languages: ['fr', 'en'],
            defaultLanguage: 'fr',
        },
        {
            id: '13',
            countryCode: 'DE',
            name: 'Germany',
            dialCode: '+49',
            languages: ['de', 'en'],
            defaultLanguage: 'de',
        },
        {
            id: '14',
            countryCode: 'GR',
            name: 'Greece',
            dialCode: '+30',
            languages: ['el', 'en'],
            defaultLanguage: 'el',
        },
        {
            id: '15',
            countryCode: 'HU',
            name: 'Hungary',
            dialCode: '+36',
            languages: ['hu', 'en'],
            defaultLanguage: 'hu',
        },
        {
            id: '16',
            countryCode: 'IS',
            name: 'Iceland',
            dialCode: '+354',
            languages: ['is', 'en'],
            defaultLanguage: 'is',
        },
        {
            id: '17',
            countryCode: 'IE',
            name: 'Ireland',
            dialCode: '+353',
            languages: ['en'],
            defaultLanguage: 'en',
        },
        {
            id: '18',
            countryCode: 'IT',
            name: 'Italy',
            dialCode: '+39',
            languages: ['it', 'en'],
            defaultLanguage: 'it',
        },
        {
            id: '19',
            countryCode: 'LV',
            name: 'Latvia',
            dialCode: '+371',
            languages: ['lv', 'ru', 'en'],
            defaultLanguage: 'lv',
        },
        {
            id: '20',
            countryCode: 'LT',
            name: 'Lithuania',
            dialCode: '+370',
            languages: ['lt', 'ru', 'en'],
            defaultLanguage: 'lt',
        },
        {
            id: '21',
            countryCode: 'LU',
            name: 'Luxembourg',
            dialCode: '+352',
            languages: ['de', 'fr', 'en'],
            defaultLanguage: 'de',
        },
        {
            id: '22',
            countryCode: 'MT',
            name: 'Malta',
            dialCode: '+356',
            languages: ['en'],
            defaultLanguage: 'en',
        },
        {
            id: '23',
            countryCode: 'NL',
            name: 'Netherlands',
            dialCode: '+31',
            languages: ['nl', 'en'],
            defaultLanguage: 'nl',
        },
        {
            id: '24',
            countryCode: 'MK',
            name: 'North Macedonia',
            dialCode: '+389',
            languages: ['mk', 'en'],
            defaultLanguage: 'mk',
        },
        {
            id: '25',
            countryCode: 'NO',
            name: 'Norway',
            dialCode: '+47',
            languages: ['no', 'en'],
            defaultLanguage: 'no',
        },
        {
            id: '26',
            countryCode: 'PL',
            name: 'Poland',
            dialCode: '+48',
            languages: ['pl', 'en'],
            defaultLanguage: 'pl',
        },
        {
            id: '27',
            countryCode: 'PT',
            name: 'Portugal',
            dialCode: '+351',
            languages: ['pt', 'en'],
            defaultLanguage: 'pt',
        },
        {
            id: '28',
            countryCode: 'RO',
            name: 'Romania',
            dialCode: '+40',
            languages: ['ro', 'en'],
            defaultLanguage: 'ro',
        },
        {
            id: '29',
            countryCode: 'RS',
            name: 'Serbia',
            dialCode: '+381',
            languages: ['sr', 'en'],
            defaultLanguage: 'sr',
        },
        {
            id: '30',
            countryCode: 'SK',
            name: 'Slovakia',
            dialCode: '+421',
            languages: ['sk', 'en'],
            defaultLanguage: 'sk',
        },
        {
            id: '31',
            countryCode: 'SI',
            name: 'Slovenia',
            dialCode: '+386',
            languages: ['sl', 'en'],
            defaultLanguage: 'sl',
        },
        {
            id: '32',
            countryCode: 'ES',
            name: 'Spain',
            dialCode: '+34',
            languages: ['es', 'en'],
            defaultLanguage: 'es',
        },
        {
            id: '33',
            countryCode: 'SE',
            name: 'Sweden',
            dialCode: '+46',
            languages: ['sv', 'en'],
            defaultLanguage: 'sv',
        },
        {
            id: '34',
            countryCode: 'CH',
            name: 'Switzerland',
            dialCode: '+41',
            languages: ['de', 'it', 'fr', 'en'],
            defaultLanguage: 'de',
        },
        {
            id: '35',
            countryCode: 'UA',
            name: 'Ukraine',
            dialCode: '+380',
            languages: ['uk', 'en'],
            defaultLanguage: 'uk',
        },
        {
            id: '36',
            countryCode: 'GB',
            name: 'United Kingdom',
            dialCode: '+44',
            languages: ['en'],
            defaultLanguage: 'en',
        },
    ],
}
