import { Tasks } from '../api'
import { store } from '../index'

export const getTasks = (dealershipId, status, dateFrom, dateTo, customerStatus) => {
    const tasksParam = { dealershipId, status, dateFrom, dateTo, customerStatus }
    store.dispatch({
        type: 'SAVE_TASKS_STARTED',
    })
    Tasks.getTasks(tasksParam)
        .then((response) => {
            store.dispatch({
                type: 'SAVE_TASKS_SUCCESS',
                // payload: response
                payload: {
                    tasks: mockTasks,
                }, // TODO replace with response data once deployed
            })
        })
        .catch((error) => {
            store.dispatch({
                type: 'SAVE_TASKS_SUCCESS',
                // payload: response
                payload: {
                    tasks: mockTasks,
                }, // TODO replace with response data once deployed
            })
        })
}

const mockTasks = [
    {
        commissionNumber: '332065',
        firstName: 'Iva',
        lastName: 'Stará',
        email: 'existing@email.com',
        fpin: null,
        vin: null,
        dateOfCreation: '2020-02-01T14:39:36Z',
        handoverDate: null,
        customerStatus: 'newCustomer',
    },
    {
        commissionNumber: '253456',
        firstName: 'Petr',
        lastName: 'Tester',
        email: 'janko@mailtest.com',
        fpin: null,
        vin: 'VINAK11R234421340',
        dateOfCreation: '2020-06-02T16:41:36Z',
        handoverDate: null,
        customerStatus: 'newCustomer',
    },
    {
        commissionNumber: '663254',
        firstName: 'Markéta',
        lastName: 'Testerka',
        email: 'kuhhhhja@mailtest.com',
        fpin: null,
        vin: 'BVINAKD1455467749',
        dateOfCreation: '2020-07-01T16:41:36Z',
        handoverDate: null,
        customerStatus: 'newCustomer',
    },
    {
        commissionNumber: '998754',
        firstName: 'Aleš',
        lastName: 'Tester',
        email: 'kalkk@mailtest.com',
        fpin: null,
        vin: 'TBMCVNA1125454003',
        dateOfCreation: '2020-06-07T16:41:36Z',
        handoverDate: null,
        customerStatus: 'newCustomer',
    },
    {
        commissionNumber: '354456',
        firstName: 'Dana',
        lastName: 'Mladá',
        email: 'dfdfddf@mailtest.com',
        fpin: null,
        vin: 'VRTIN54A112554670',
        dateOfCreation: '2020-05-01T13:41:36Z',
        handoverDate: null,
        customerStatus: 'newCustomer',
    },
    {
        commissionNumber: '902145',
        firstName: 'Lada',
        lastName: 'Tenká',
        email: 'hhthth@malmail.com',
        fpin: null,
        vin: 'TBMSFNK1125546707',
        dateOfCreation: '2020-05-07T16:41:36Z',
        handoverDate: null,
        customerStatus: 'newCustomer',
    },
    {
        commissionNumber: '452214',
        firstName: 'Jana',
        lastName: 'Nová',
        email: 'ahoj@mailtest.com',
        fpin: '123456789',
        vin: 'BBTSD54E324545577',
        dateOfCreation: '2020-05-01T16:41:36Z',
        handoverDate: '2020-06-02',
        customerStatus: 'vinAndHandoverDateComplete',
    },
    {
        commissionNumber: '123456',
        firstName: 'Jan',
        lastName: 'Kudela',
        email: 'mailtest@mailtest.com',
        fpin: '123456789',
        vin: 'VINAK11E255467044',
        dateOfCreation: '2020-07-01T16:41:36Z',
        handoverDate: '2020-07-03',
        customerStatus: 'customerRegistered',
    },
    {
        commissionNumber: '573462982',
        firstName: 'Alois',
        lastName: 'Žloutek',
        email: 'user@mailinator.com',
        fpin: null,
        vin: 'WAUD2AFD7DN006931',
        dateOfCreation: '2020-07-03T13:59:30.342548Z',
        handoverDate: '2020-07-03',
        customerStatus: 'vinAndHandoverDateComplete',
    },
    {
        commissionNumber: '573462911',
        firstName: 'Alois',
        lastName: 'Jedlička',
        email: 'useddr@mailinator.com',
        fpin: null,
        vin: 'WUDD2AFD7DN006931',
        dateOfCreation: '2020-07-03T14:19:41.393835Z',
        handoverDate: '2020-06-03',
        customerStatus: 'mobileAppInstalled',
    },
    {
        commissionNumber: '5053462911',
        firstName: 'Hynek',
        lastName: 'Novák',
        email: 'hnovakk@mailinator.com',
        fpin: null,
        vin: 'WUDD2AFD7DN006000',
        dateOfCreation: '2020-07-03T14:56:31.150488Z',
        handoverDate: '2020-06-03',
        customerStatus: 'mobileAppInstalled',
    },
    {
        commissionNumber: '5053469671',
        firstName: 'ZHynek',
        lastName: 'ZNovák',
        email: 'hnovfakk@mailinator.com',
        fpin: null,
        vin: 'WUDD2AFF7DN006093',
        dateOfCreation: '2020-07-03T15:25:09.320713Z',
        handoverDate: '2020-06-03',
        customerStatus: 'vinAndHandoverDateComplete',
    },
    {
        commissionNumber: '3953469671',
        firstName: 'Nenul',
        lastName: 'Kovář',
        email: 'hntrtfakk@mailinator.com',
        fpin: null,
        vin: 'BTRD2AFF7DN006093',
        dateOfCreation: '2020-07-03T16:28:01.161858Z',
        handoverDate: '2020-06-20',
        customerStatus: 'vinAndHandoverDateComplete',
    },
]
