import React from 'react'
import { useSelector } from 'react-redux'
import PrimaryButton from '../Buttons/PrimaryButton'
import { useHistory } from 'react-router'
import { SkipButton } from '../Buttons/SkipButton'
import { closeDealerProfilePopup } from '../../actions/popupsActions'
import { useOnOutsideClick } from './PopUp'

export default function DealerProfilePopup({ active, close }) {
    const information = useSelector((state) => state.dealer.information)
    const history = useHistory()

    const content = (
        <div className="dialog__content-wrapper">
            <div className="dialog__content-row">
                <div className="dialog__content-col">{information.firstName}</div>
                <div className="dialog__content-col">{information.lastName}</div>
            </div>
        </div>
    )

    const logout = () => {
        closeDealerProfilePopup()
        history.push({
            pathname: '/logout',
        })
    }

    const download = () => {
        // TODO
    }

    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(firstDay)
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(firstDay)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(firstDay)
    const downloadButtonText = 'Download Report ' + da + mo + ye

    const actions = (
        <div className="dialog__buttons-wrapper">
            <SkipButton clickAction={download} label={downloadButtonText} />
            <PrimaryButton clickAction={logout} label="Log out" />
        </div>
    )

    const config = {
        title: 'Dealer Profile',
        type: 'DEALER_PROFILE',
        actions: actions,
        body: content,
    }
    const { innerBorderRef } = useOnOutsideClick(close, active)

    return (
        <div className={active ? 'dialog dialog--open' : 'dialog'}>
            <div className="dialog__overlay" />
            <div ref={innerBorderRef} className="dialog__content dialog__content-right">
                <div className={'dialog__header'}>
                    <div className={'dialog__header__title'}>{config.title}</div>
                    <div className={'dialog__header__closer'} onClick={close}>
                        <div className={'dialog__header__cross-icon'} />
                    </div>
                </div>
                <div className={'dialog__body'}>{config.body}</div>
                <div className={'dialog__actions'}>{actions}</div>
            </div>
        </div>
    )
}
