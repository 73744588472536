const initialState = {
    loading: false,
    error: false,
    information: {
        id: null,
        firstName: null,
        lastName: null,
        country: null,
        dealershipId: null,
    },
}

export const dealerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEALER_DATA_STARTED':
            return {
                ...state,
                loading: true,
            }
        case 'SET_DEALER_DATA_SUCCESS':
            return {
                ...action.payload,
                loading: false,
                error: false,
            }
        case 'SET_DEALER_DATA_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            return state
    }
}
