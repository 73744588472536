import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function Dashboard({ fakeLoging }) {
    const history = useHistory()

    useEffect(() => {
        history.push('/customers')
    }, [history])

    // TODO loading
    return (
        <div>
            Hello, here we show default content or redirect straight to the tasks or customers?
        </div>
    )
}
