import React, { useState, Fragment, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BackLinkArrow from '../components/BackLinkArrow/BackLinkArrow'

const Wrapper = styled.div`
    padding: 50px 100px;
`

const PageTitle = styled.div`
    color: #4b4a4b;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 32px;
`

export default function Page({ children, title, withBackLink, backLinkUrl }) {
    return (
        <Wrapper>
            {withBackLink && <BackLinkArrow backLinkUrl={backLinkUrl} />}
            {title && <PageTitle>{title}</PageTitle>}
            {children}
        </Wrapper>
    )
}
