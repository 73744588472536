import {
    CustomerInformationFormValidator,
    OrderDetailsFormValidator,
    SkodaIdentFormValidator,
} from '../pages/Registration/validators'

const skodaIdentDefault = {
    licenceNumber: '',
    issueDate: null,
    expirationDate: null,
    countryOfIssue: 0,
    dateOfBirth: null,
    errors: {
        licenceNumber: null,
        issueDate: null,
        expirationDate: null,
        countryOfIssue: null,
        dateOfBirth: null,
    },
    liveValidation: false,
}

const initialState = {
    loading: false,
    error: false,
    skippedSteps: [],
    customerInformation: {
        email: '',
        salutation: {
            id: '0',
        },
        firstName: '',
        lastName: '',
        middleName: '',
        nickname: '',
        country: {
            id: '0',
        },
        address: null,
        dialCodeCountryId: '0',
        phoneNumber: '',
        preferredLanguage: 'none',
        errors: {
            email: null,
            salutation: null,
            firstName: null,
            lastName: null,
            nickname: null,
            country: null,
            address: null,
            dialCodeCountryId: null,
            phoneNumber: null,
            preferredLanguage: null,
        },
        liveValidation: false,
        addressValidationRules: {},
        noErrors: {
            email: null,
            salutation: null,
            firstName: null,
            lastName: null,
            nickname: null,
            country: null,
            address: null,
            dialCodeCountryId: null,
            phoneNumber: null,
            preferredLanguage: null,
        },
    },
    skodaIdent: skodaIdentDefault,
    orderDetails: {
        commissionNumber: '',
        vin: '',
        preferredServicePartner: 'Example SKODA dealer, Vinohradska 420, Prague 3',
        handoverDate: null,
        plannedProductionDate: null,
        errors: {
            commissionNumber: null,
            vin: null,
            handoverDate: null,
            plannedProductionDate: null,
            oneOfDates: false,
        },
        liveValidation: false,
    },
    consentVerification: {
        gdprAgreed: false,
        activateServicesAgreed: false,
        pspSetupAgreed: false,
    },
}

export const registrationProcessReducer = (state = initialState, action) => {
    let validationErrors = {}
    let exists = false
    switch (action.type) {
        case 'SET_EMAIL':
            return {
                ...initialState,
                customerInformation: {
                    ...initialState.customerInformation,
                    email: action.payload.email,
                },
            }
        case 'ADD_SKIPPED_STEP':
            exists = state.skippedSteps.find((s) => s === action.payload.value)
            const newSkippedSteps = state.skippedSteps
            if (!exists) {
                newSkippedSteps.push(action.payload.value)
            }
            return {
                ...state,
                skippedSteps: newSkippedSteps,
            }
        case 'REMOVE_SKIPPED_STEP':
            return {
                ...state,
                skippedSteps: state.skippedSteps.filter((s) => s !== action.payload.value),
            }
        case 'CLEAR_NEW_CUSTOMER':
            return {
                ...initialState,
                customerInformation: {
                    ...initialState.customerInformation,
                },
            }
        case 'ADD_CUSTOMER_INFO_FROM_GROUPID':
            return {
                ...state,
                customerInformation: {
                    ...action.payload.customer,
                    country: action.payload.customer.address.country,
                    errors: {
                        email: null,
                        salutation: null,
                        firstName: null,
                        lastName: null,
                        nickname: null,
                        country: null,
                        address: {
                            addressType: null,
                            street: null,
                            houseNumber: null,
                            floor: null,
                            room: null,
                            additionalInformation: null,
                            zipCode: null,
                            city: null,
                            buildingName: null,
                            district: null,
                            province: null,
                            addressLine1: null,
                            addressLine2: null,
                            county: null,
                            eirCode: null,
                            provinceCode: null,
                            country: null,
                        },
                        dialCodeCountryId: null,
                        phoneNumber: null,
                    },
                    liveValidation: false,
                    addressValidationRules: {},
                    noErrors: {
                        email: null,
                        salutation: null,
                        firstName: null,
                        lastName: null,
                        nickname: null,
                        country: null,
                        address: null,
                        dialCodeCountryId: null,
                        phoneNumber: null,
                    },
                },
            }
        case 'SAVE_CUSTOMER_INFORMATION':
            validationErrors = state.customerInformation.errors
            let newNickname = state.customerInformation.nickname
            if (action.payload.name === 'firstName') {
                newNickname =
                    action.payload.value + ' ' + state.customerInformation.lastName.substr(0, 1)
            }
            if (action.payload.name === 'lastName') {
                newNickname =
                    state.customerInformation.firstName + ' ' + action.payload.value.substr(0, 1)
            }
            if (action.payload.name === 'nickname') {
                newNickname = action.payload.value
            }
            if (state.customerInformation.liveValidation) {
                const newCustomerInformation = {
                    ...state.customerInformation,
                    [action.payload.name]: action.payload.value,
                }
                validationErrors = CustomerInformationFormValidator(newCustomerInformation)
            }
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    nickname: newNickname,
                    errors: validationErrors,
                    [action.payload.name]: action.payload.value,
                },
            }
        case 'SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD':
            validationErrors = state.customerInformation.errors
            if (state.customerInformation.liveValidation) {
                if (action.payload.name === 'preferredLanguage') {
                    const newCustomerInformation = {
                        ...state.customerInformation,
                        [action.payload.name]: action.payload.value,
                    }
                    validationErrors = CustomerInformationFormValidator(newCustomerInformation)
                } else {
                    const newCustomerInformation = {
                        ...state.customerInformation,
                        address: {
                            ...state.customerInformation.address,
                            [action.payload.name]: action.payload.value,
                        },
                    }
                    validationErrors = CustomerInformationFormValidator(newCustomerInformation)
                }
            }
            if (action.payload.name === 'preferredLanguage') {
                return {
                    ...state,
                    customerInformation: {
                        ...state.customerInformation,
                        [action.payload.name]: action.payload.value,
                        errors: validationErrors,
                    },
                }
            }
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    address: {
                        ...state.customerInformation.address,
                        [action.payload.name]: action.payload.value,
                    },
                    errors: validationErrors,
                },
            }
        case 'SAVE_CUSTOMER_INFORMATION_ERRORS':
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    errors: action.payload.value,
                },
            }
        case 'SET_CUSTOMER_INFORMATION_LIVE_VALIDATION':
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    liveValidation: true,
                },
            }
        case 'ADD_ADDRESS_FIELDS':
            const newAddressFields = action.payload.value
            const errorFields = Object.entries(newAddressFields)
                .filter(([, v]) => v.required === true || v.validation)
                .map(([k]) => k)
            const validationRules = {}
            Object.entries(newAddressFields)
                .filter(([, v]) => v.required === true || v.validation)
                .forEach(([k, v]) => {
                    validationRules[k] = {
                        required: v.required,
                        validation: v.validation,
                    }
                })
            let newErrors = {
                ...state.customerInformation.errors,
                address: {},
            }
            let noErrors = {
                ...state.customerInformation.noErrors,
                address: {},
            }
            errorFields.forEach((f) => {
                if (f !== 'language') {
                    newErrors.address[f] = null
                    noErrors.address[f] = null
                }
            })
            let newFields = {
                ...state.customerInformation,
                address: {},
            }
            Object.entries(newAddressFields).forEach(([k]) => {
                if (k !== 'language') {
                    if (k === 'addressType') {
                        return (newFields.address[k] = 0)
                    }
                    return (newFields.address[k] = '')
                }
            })
            newFields.errors = {
                ...newErrors,
            }
            newFields.noErrors = {
                ...noErrors,
            }
            newFields.addressValidationRules = validationRules
            return {
                ...state,
                customerInformation: newFields,
            }
        case 'SAVE_SKODA_IDENT':
            validationErrors = state.skodaIdent.errors
            if (state.skodaIdent.liveValidation) {
                const newSkodaIdent = {
                    ...state.skodaIdent,
                    [action.payload.name]: action.payload.value,
                }
                validationErrors = SkodaIdentFormValidator(newSkodaIdent)
            }
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    [action.payload.name]: action.payload.value,
                    errors: validationErrors,
                },
            }
        case 'REMOVE_SKODA_IDENT_DATA':
            return {
                ...state,
                skodaIdent: skodaIdentDefault,
            }
        case 'REMOVE_SKODA_IDENT_ERRORS':
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    errors: {
                        licenceNumber: null,
                        issueDate: null,
                        expirationDate: null,
                        countryOfIssue: null,
                        dateOfBirth: null,
                    },
                    liveValidation: false,
                },
            }
        case 'REMOVE_ORDER_DETAILS_ERRORS':
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    errors: {
                        commissionNumber: null,
                        vin: null,
                        handoverDate: null,
                        plannedProductionDate: null,
                        oneOfDates: false,
                    },
                    liveValidation: false,
                },
            }
        case 'SAVE_SKODA_IDENT_ERRORS':
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    errors: action.payload.value,
                },
            }
        case 'SET_SKODA_IDENT_LIVE_VALIDATION':
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    liveValidation: true,
                },
            }
        case 'SAVE_ORDER_DETAILS':
            validationErrors = state.orderDetails.errors
            if (state.orderDetails.liveValidation) {
                const newOrderDetails = {
                    ...state.orderDetails,
                    [action.payload.name]: action.payload.value,
                }
                validationErrors = OrderDetailsFormValidator(newOrderDetails)
            }
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    [action.payload.name]: action.payload.value,
                    errors: validationErrors,
                },
            }
        case 'SAVE_ORDER_DETAILS_ERRORS':
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    errors: action.payload.value,
                },
            }
        case 'SET_ORDER_DETAILS_LIVE_VALIDATION':
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    liveValidation: true,
                },
            }
        case 'SAVE_CONSENT_VERIFICATION':
            return {
                ...state,
                consentVerification: {
                    ...state.consentVerification,
                    [action.payload.name]: action.payload.value,
                },
            }
        case 'SET_CUSTOMER_DATA_STARTED':
            return {
                ...state,
                loading: true,
            }
        case 'SET_CUSTOMER_DATA_SUCCESS':
            // TODO fix
            return {
                ...action.payload,
                loading: false,
                error: false,
            }
        case 'SET_CUSTOMER_DATA_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            return state
    }
}
