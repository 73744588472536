import { useHistory } from 'react-router-dom'
import React, { useCallback, Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import { getTasks } from '../../actions/taskActions'
import { useSelector } from 'react-redux'
import { closeToast } from '../../actions/popupsActions'
import Arrow from '../../assets/images/icon-arrow-right-light.svg'

const ArrowDown = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -3px;
    right: -11px;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #c0c0c0 transparent transparent transparent;
`
const ArrowUp = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -3px;
    right: -11px;
    border-style: solid;
    border-width: 0px 4px 6px 4px;
    border-color: transparent transparent #c0c0c0 transparent;
`

const Styles = styled.div`
    table {
        font-family: 'SKODA Next', sans-serif;
        font-size: 14px;
        width: 100%;
        border-spacing: 0px 8px;

        thead {
            text-transform: uppercase;
            font-size: 10px;
            color: #808285;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
        }

        tbody {
            tr {
                background: white;
                cursor: pointer;

                &:hover {
                    outline: 1px solid lightgrey;
                }
            }
        }

        th {
            padding: 12px 24px;
            span {
                position: relative;
            }
        }

        td {
            padding: 24px;
        }

        td:last-child {
            padding: 0px;
        }

        th,
        td {
            text-align: left;
        }
    }
`

const ArrowGreenRight = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    img {
        width: 100%;
    }
`

function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    )

    const firstPageRows = rows.slice(0, 20)
    const history = useHistory()

    const clickOnRow = useCallback(
        (row) => () => {
            closeToast()
            history.push({
                pathname: '/customers/' + row.commissionNumber,
                state: { customer: row },
            })
        },
        [history]
    )

    return (
        <Fragment>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <span>
                                        {column.render('Header')}
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <ArrowDown />
                                            ) : (
                                                <ArrowUp />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {firstPageRows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} onClick={clickOnRow(row.original)}>
                                {row.cells.map((cell, index) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                                <td>
                                    <ArrowGreenRight>
                                        <img src={Arrow} />
                                    </ArrowGreenRight>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <br />
            <div>
                Showing the first {rows.length} results of {rows.length}
            </div>
        </Fragment>
    )
}

export default function SortingTable({ data }) {
    const customers = useSelector((state) => state.tasks.taskList)
    useEffect(() => {
        if (customers.length === 0) {
            getTasks('dealer_123_M', 'all', null, null, null)
        }
    }, [customers.length])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Comission number',
                accessor: 'commissionNumber',
            },
            {
                Header: 'Surname',
                accessor: 'lastName',
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Pairing Code',
                accessor: 'fpin',
            },
            {
                Header: 'VIN',
                accessor: 'vin',
            },
            {
                Header: 'Created',
                accessor: 'dateOfCreation',
            },
            {
                Header: 'Handover date',
                accessor: 'handoverDate',
            },
            {
                Header: 'Customer status',
                accessor: 'customerStatus',
            },
        ],
        []
    )
    return (
        <Fragment>
            <Styles>
                <Table columns={columns} data={data} />
            </Styles>
        </Fragment>
    )
}
