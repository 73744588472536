const CbaApi = require('connect_boarding_application_api/dist')
const {
    CustomersApi,
    VehiclesApi,
    TasksApi,
    I18nApi,
} = require('connect_boarding_application_api/dist')

const defaultClient = CbaApi.ApiClient.instance

defaultClient.authentications = {
    identityKit: {
        type: 'basic',
        accessToken: 'foo',
        idToken: 'foo',
    },
}

function setAccessToken(accessToken) {
    defaultClient.authentications.identityKit.accessToken = accessToken
}

function setIdToken(idToken) {
    defaultClient.authentications.identityKit.idToken = idToken
}

function setBasePath(basePath) {
    defaultClient.basePath = basePath
}

if (process && process.env.NODE_ENV === 'development') {
    // FOR LOCAL DEVELOPMENT MOCKING PURPOSES
    defaultClient.basePath = 'https://api.dev.skoda-nrp.com'.replace(/\/+$/, '')
    // defaultClient.basePath = 'http://localhost:8080'.replace(/\/+$/, '')
} else {
    // OVERRIDE SINCE WE CANT RELY ON URL FROM OAS CONTRACT, SET DYNAMICALLY
    defaultClient.basePath = `${window.location.protocol}//api.${window.location.hostname}`
    // IF MOCK USE PORT 3030
    defaultClient.basePath = `${defaultClient.basePath}${
        window.location.hostname.match(/^mock\./) ? ':3030' : ''
    }`
}

const Customers = new CustomersApi()
const Vehicles = new VehiclesApi()
const Tasks = new TasksApi()
const I18n = new I18nApi()

export { Customers, Vehicles, Tasks, I18n, setAccessToken, setIdToken, setBasePath }
