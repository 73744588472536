import { SelectIcon } from '../Form/TextInput'
import MenuItem from '@material-ui/core/MenuItem'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import DatePicker from '../Form/DatePicker'
import { DateTime, Duration, Interval } from 'luxon'
import Select from '@material-ui/core/Select'

const FilterPopUp = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 34px;
    right: 0;
    z-index: 1;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 32px;
`
const LabelColumn = styled.div`
    align-self: flex-start;
    font-weight: bold;
`
const ContentColumn = styled.div`
    display: flex;
    font-weight: bold;
    margin-right: 30px;
`
const Filters = styled.div`
    display: flex;
    margin-bottom: 42px;
`
const FilterColumn = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-self: flex-start;
    flex: 1 1 auto;
`
const StatusFilterColumn = styled(FilterColumn)`
    display: flex;
    flex: 1 0 250px;
`

const StatusContentColumn = styled(ContentColumn)`
    flex: 1 0 auto;
`
const DateSeparator = styled.div`
    background-color: #808285;
    align-self: center;
    content: '';
    margin: 0 20px;
    width: 8px;
    height: 1px;
`
const StatusSelect = styled(Select)`
    margin-top: 12px;
`
const FilterDatePicker = styled(DatePicker)`
    flex: 0 1 50px;
`

export default function FilterPopup() {
    const [filterStatus, setFilterStatus] = useState('')
    const handleChange = (e) => {
        setFilterStatus(e.target.value)
    }

    const mockSelect = ['Enter F-PIN', 'Enter Handover Date', 'DMS incomplete']

    return (
        <FilterPopUp>
            <Filters>
                <FilterColumn>
                    <LabelColumn>Created on</LabelColumn>
                    <ContentColumn>
                        <FilterDatePicker />
                        <DateSeparator />
                        <FilterDatePicker />
                    </ContentColumn>
                </FilterColumn>
                <StatusFilterColumn>
                    <LabelColumn>Status</LabelColumn>
                    <StatusContentColumn>
                        <StatusSelect
                            // formId={formId}
                            onChange={handleChange}
                            name={'filterStatus'}
                            value={filterStatus}
                            IconComponent={() => <SelectIcon />}
                            fullWidth
                        >
                            {mockSelect &&
                                mockSelect.map((option, index) => {
                                    return (
                                        <MenuItem value={index} key={index}>
                                            {option}
                                        </MenuItem>
                                    )
                                })}
                        </StatusSelect>
                    </StatusContentColumn>
                </StatusFilterColumn>
            </Filters>
            <SecondaryButton label="Apply" />
        </FilterPopUp>
    )
}
