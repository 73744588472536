import { store } from '../index'
import { Customers } from '../api'

export const getCustomer = (email) => {
    store.dispatch({
        type: 'GET_CUSTOMER_STARTED',
    })
    Customers.getCustomer(email)
        .then((response) => {
            store.dispatch({
                type: 'GET_CUSTOMER_SUCCESS',
                // payload: response
                payload: {
                    customer: mockCustomer,
                }, // TODO replace with response data once deployed
            })
        })
        .catch((error) => {
            store.dispatch({
                type: 'GET_CUSTOMER_SUCCESS',
                // payload: response
                payload: {
                    customer: mockCustomer,
                }, // TODO replace with response data once deployed
            })
        })
}

export const getCustomerGroupIdData = (email) => {
    store.dispatch({
        type: 'GET_CUSTOMER_STARTED',
    })
    Customers.getCustomerGroupIdData(email)
        .then((response) => {
            store.dispatch({
                type: 'GET_CUSTOMER_SUCCESS',
                // payload: response
                payload: {
                    customer: mockCustomerGroupId,
                }, // TODO replace with response data once deployed
            })
            store.dispatch({
                type: 'ADD_CUSTOMER_INFO_FROM_GROUPID',
                // payload: response
                payload: {
                    customer: mockCustomerGroupId,
                }, // TODO replace with response data once deployed
            })
        })
        .catch((error) => {
            store.dispatch({
                type: 'GET_CUSTOMER_SUCCESS',
                // payload: response
                payload: {
                    customer: mockCustomerGroupId,
                }, // TODO replace with response data once deployed
            })
            store.dispatch({
                type: 'ADD_CUSTOMER_INFO_FROM_GROUPID',
                // payload: response
                payload: {
                    customer: mockCustomerGroupId,
                }, // TODO replace with response data once deployed
            })
        })
}

export const checkCustomer = (email) => {
    let mock
    if (email === 'existing@email.com') {
        mock = mockCustomerCheckExisting
    } else if (email === 'groupid@email.com') {
        mock = mockCustomerCheckGid
    } else if (email === 'ident@email.com') {
        mock = mockCustomerCheckSkodaIdent
    } else {
        mock = mockCustomerCheckNew
    }

    store.dispatch({
        type: 'CHECK_CUSTOMER_STARTED',
    })
    Customers.checkCustomer(email)
        .then((response) => {
            store.dispatch({
                type: 'CHECK_CUSTOMER_SUCCESS',
                // payload: response
                payload: {
                    customerCheck: mock,
                }, // TODO replace with response data once deployed
            })
        })
        .catch((error) => {
            store.dispatch({
                type: 'CHECK_CUSTOMER_SUCCESS',
                // payload: response
                payload: {
                    customerCheck: mock,
                }, // TODO replace with response data once deployed
            })
        })
}
const mockCustomerCheckNew = {
    groupIdAccount: false,
    cbaAccount: false,
    skodaIdAccount: false,
    connectAccount: false,
}

const mockCustomerCheckExisting = {
    groupIdAccount: false,
    cbaAccount: true,
    skodaIdAccount: false,
    connectAccount: false,
}

const mockCustomerCheckGid = {
    groupIdAccount: true,
    cbaAccount: false,
    skodaIdAccount: false,
    connectAccount: false,
}

const mockCustomerCheckSkodaIdent = {
    groupIdAccount: false,
    cbaAccount: false,
    skodaIdAccount: true,
    connectAccount: false,
}

const mockCustomer = {
    customerInformation: {
        email: 'existing@email.com',
        firstName: 'Jan',
        middleName: null,
        lastName: 'Kudela',
        nickname: 'jtjtjt',
        salutation: {
            id: 3,
            name: 'Mr.',
        },
        address: {
            addressType: 'home',
            street: 'dolni',
            houseNumber: '44',
            floor: '1',
            room: null,
            additionalInformation: null,
            zipCode: '62700',
            city: 'Brno',
            buildingName: null,
            district: null,
            province: null,
            addressLine1: null,
            addressLine2: null,
            county: '',
            eirCode: null,
            provinceCode: null,
            country: {
                id: 8,
                countryCode: 'CZ',
                name: 'Czech Republic',
                dialCode: '+420',
                languages: ['cs', 'en'],
            },
        },
        dialCodeCountryId: 1,
        preferredLanguage: 'en',
        phoneNumber: '605123321',
        lastModifiedDate: '2020-07-01T16:42:58Z',
    },
    skodaIdent: {
        licenceNumber: '1234546',
        issueDate: null,
        expirationDate: null,
        countryOfIssue: {
            id: 8,
            countryCode: 'CZ',
            name: 'Czech Republic',
            dialCode: '+420',
            languages: ['cs', 'en'],
        },
        dateOfBirth: '1995-07-02',
    },
    orderDetails: {
        vin: 'VINAK11E255467044',
        commissionNumber: '123456',
        handoverDate: '2020-07-03',
        preferredServicePartner: 'Servis Auto Skoda Brno',
    },
    summaryData: {
        dataProcessingConsentAgreed: true,
        enrollmentConsentAgreed: true,
    },
}

const mockCustomerGroupId = {
    email: 'groupid@email.com',
    firstName: 'Jan',
    middleName: null,
    lastName: 'Kudela',
    nickname: 'jank',
    salutation: {
        id: 1,
        name: 'Mr.',
    },
    address: {
        addressType: '1',
        street: 'dolni',
        houseNumber: '44',
        floor: '1',
        room: null,
        additionalInformation: null,
        zipCode: '62700',
        city: 'Brno',
        buildingName: null,
        district: null,
        province: null,
        addressLine1: null,
        addressLine2: null,
        county: '',
        eirCode: null,
        provinceCode: null,
        country: {
            id: 8,
            countryCode: 'CZ',
            name: 'Czech Republic',
            dialCode: '+420',
            languages: ['cs', 'en'],
        },
    },
    preferredLanguage: 'en',
    phoneNumber: '+420605123321',
    lastModifiedDate: '2020-07-01T16:42:58Z',
}
