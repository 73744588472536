import React from 'react'
import './theme.scss'
import datepicker from '../../assets/images/event.svg'
import Icon from '@material-ui/core/Icon'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { EMPTY, getErrorText, INVALID_DATE } from '../../pages/Registration/validators'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputTooltipLabel from '../Tooltip/InputTooltipLabel'
import DatePickerToolbar from './DatePickerToolbar'

function DatePickerIcon() {
    return (
        <Icon className="text-input-select-icon">
            <img src={datepicker} alt="datepicker" />
        </Icon>
    )
}

export default function DatePicker({
    formId,
    inputId,
    handleChange,
    label,
    value,
    error,
    oneOfDates,
    tooltipText,
}) {
    const calendarId = `${formId}-${inputId}-datepicker`
    const isError = !oneOfDates && error !== null && error !== undefined

    const labelElem = <InputTooltipLabel labelText={label} tooltipText={tooltipText} />
    return (
        <div className="text-input-wrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <>
                    <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        id={calendarId}
                        label={labelElem}
                        value={value}
                        onChange={handleChange}
                        autoOk
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        emptyLabel="DD/MM/YYYY"
                        keyboardIcon={<DatePickerIcon />}
                        error={isError}
                        InputLabelProps={{
                            style: {
                                width: '230px',
                            },
                        }}
                        ToolbarComponent={DatePickerToolbar}
                    />
                    {/* regular validation */}
                    {oneOfDates === undefined && isError && INVALID_DATE !== error && (
                        <FormHelperText
                            id="component-error-text"
                            error={isError}
                            className="text-input-helper"
                        >
                            {label + getErrorText(error)}
                        </FormHelperText>
                    )}
                    {/* validation in group of inputs */}
                    {oneOfDates === false && isError && EMPTY === error && (
                        <FormHelperText
                            id="component-error-text"
                            error={isError}
                            className="text-input-helper"
                        >
                            {'One of these dates is mandatory.'}
                        </FormHelperText>
                    )}
                    {/* validation in group of inputs with future/past */}
                    {oneOfDates === false && isError && EMPTY !== error && INVALID_DATE !== error && (
                        <FormHelperText
                            id="component-error-text"
                            error={isError}
                            className="text-input-helper"
                        >
                            {label + getErrorText(error)}
                        </FormHelperText>
                    )}
                </>
            </MuiPickersUtilsProvider>
        </div>
    )
}
