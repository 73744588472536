const initialState = {
    createNewCustomerActive: false,
    cancelNewCustomerActive: false,
    hasSkodaIdentActive: false,
    dealerProfilePopupActive: false,
    cancelYesUrlAction: null,
    toastActive: null,
}

export const popupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CREATE_NEW_CUSTOMER_ACTIVE':
            return {
                ...state,
                createNewCustomerActive: action.payload.value,
            }
        case 'SET_CANCEL_NEW_CUSTOMER_ACTIVE':
            return {
                ...state,
                cancelNewCustomerActive: action.payload.value,
                cancelYesUrlAction: action.payload.onYesUrl,
            }
        case 'SET_HAS_SKODA_IDENT_ACTIVE':
            return {
                ...state,
                hasSkodaIdentActive: action.payload.value,
            }
        case 'SET_DEALER_POPUP_ACTIVE':
            return {
                ...state,
                dealerProfilePopupActive: action.payload.value,
            }
        case 'SET_TOAST_ACTIVE':
            return {
                ...state,
                toastActive: action.payload.value,
            }
        default:
            return state
    }
}
