import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import './theme.scss'
import ReactToPrint from 'react-to-print'

class ConsentDocument extends React.Component {
    render() {
        return (
            <div ref={this.props.propsRef}>
                <div className="content-wrapper">
                    <h1>Consent to the processing of personal data</h1>
                    <h3>Purpose:</h3>
                    <p>Pre-activation of ŠKODA Connect services</p>
                    <h3>Description of the purpose of processing:</h3>
                    <p>
                        The personal data will be used for the purposes of pre-registration of your
                        ŠKODA Connect account and for activation of the ŠKODA Connect Services
                        ordered.
                    </p>
                    <h3>Categories of the personal data:</h3>
                    <ul className="caret-list">
                        <li>E-mail address</li>
                        <li>Title</li>
                        <li>Name</li>
                        <li>Surname</li>
                        <li>Country of Use</li>
                        <li>Language for communication</li>
                        <li>Date of Birth (for ŠKODA Ident only, not mandatory)</li>
                        <li>
                            Driving licence – Number, Date of Issue, Date of Expiry, Country of
                            Issue (for ŠKODA Ident only, not mandatory)
                        </li>
                        <li>VIN/Commission Number</li>
                    </ul>
                    <h3>I agree that the following steps shall be carried out by the Dealer:</h3>
                    <ul className="caret-list">
                        <li>Pre-filling of ŠKODA Connect user account</li>
                        <li>Entering the VIN number to the system</li>
                        <li>
                            Pre-setting of Preferred Service Partner and Pre-activation of Service
                            Scheduling
                        </li>
                        <li>Activation of ŠKODA Connect Services</li>
                    </ul>
                    <div style={{ height: '30px' }} />
                    <p>
                        Date:
                        <input type="text" className="signature" />
                    </p>
                    <div style={{ height: '10px' }} />
                    <p>
                        Customer signature:
                        <input type="text" className="signature" />
                    </p>
                    <div className="pb-before" />
                    <div className="new-page">&nbsp;</div>
                    <h1>User account pre-registration</h1>
                    <p>Dealer {this.props.dealer.id} hereinafter referred to as “Dealer”</p>
                    <h3>Article 1 – General Description</h3>
                    <ol className="bracket-list">
                        <li>
                            With the purchase of a new ŠKODA vehicle with built-in mobile online
                            services, the Customer may use the service free of charge for a
                            specified period under the General Terms and Conditions of ŠKODA
                            Connect. In order to use ŠKODA Connect, the Customer has to create a
                            user account at the ŠKODA Connect Portal, enter into a contract on the
                            use of ŠKODA Connect with ŠKODA AUTO at the ŠKODA Connect Portal and
                            perform a one-off connection process to link the vehicle to the user
                            account after completing the Order.
                        </li>
                        <li>
                            This Agreement regulates the process of pre-registration and subsequent
                            assistance with the creation of a ŠKODA Connect user account and with
                            matching the car to this account by the Dealer. For these purposes, the
                            Customer agrees to process their personal data contained in this
                            Purchase Contract, as evidenced by their signature. The use of personal
                            data for the above mentioned purpose is made on Customer's request
                            without a reward for the Dealer. If the customer decides not to use this
                            service, the validity of the Purchase Contract is not affected. The
                            Dealer undertakes to keep the Customer Data in a secure form in
                            accordance with the local regulations.
                        </li>
                    </ol>
                    <h3>Article 2 - Service Description</h3>
                    <p>I agree that the following shall be carried out by the Dealer:</p>
                    <ul className="dot-list">
                        <li>
                            One-time pre-filling of ŠKODA Connect user account. The Dealer enters
                            Customer Data into the corresponding system, based on the Purchase
                            Contract, including the Customer's provided email address, which serves
                            as a unique identifier.
                        </li>
                        <li>
                            Pre-activation of Service Scheduling, which informs the preferred
                            partner about the need to service the car (see ŠKODA Connect Terms and
                            Conditions, www.skoda-connect.com).
                        </li>
                        <li>
                            Subsequently, before the car is handed over to the Customer, the VIN
                            number of the car is entered into the system by the Dealer. An email
                            message is generated with a link to the portal where the password is
                            created and the legal documents are confirmed by the Customer and with a
                            link to the Connect App, where the activation PIN is generated by the
                            Customer. The generated activation PIN can be shared to the Dealer.
                        </li>
                        <li>
                            The activation PIN can be used by the Dealer to activate ŠKODA Connect
                            services (The generated activation PIN is only used to activate ŠKODA
                            Connect services and is valid for 30 days. This parameter cannot be used
                            for any other action than the activation of ŠKODA Connect services).
                        </li>
                    </ul>
                    <p>
                        I hereby acknowledge that, by signing the above stated declaration, I agree
                        with the provision of services declared in Article 2:
                    </p>
                    <div style={{ height: '80px' }} />
                    <p>
                        Date: <input type="text" className="signature" />
                    </p>
                    <div style={{ height: '10px' }} />
                    <p>
                        Customer signature: <input type="text" className="signature" />
                    </p>
                    <div style={{ height: '80px' }} />
                </div>
            </div>
        )
    }
}

export default function ConsentDocumentPreview() {
    const componentRef = useRef()
    const information = useSelector((state) => state.dealer.information)

    let printRef
    useEffect(() => {
        printRef.handlePrint()
    }, [printRef])

    return (
        <div>
            <ReactToPrint
                ref={(el) => (printRef = el)}
                trigger={() => <div />}
                content={() => componentRef.current}
            />
            <ConsentDocument propsRef={componentRef} dealer={information} />
        </div>
    )
}
