import React from 'react'
import './theme.scss'
import InputTooltip from './InputTooltip'

export default function InputTooltipLabel({ labelText, tooltipText }) {
    return (
        <div className="text-input-tooltip">
            <div className="text-input-tooltip__text">{labelText}</div>
            {tooltipText && <InputTooltip text={tooltipText} />}
        </div>
    )
}
