import { store } from '../index'

export const showCreateNewCustomer = () => {
    store.dispatch({
        type: 'REMOVE_CUSTOMER_CHECK',
    })
    store.dispatch({
        type: 'SET_CREATE_NEW_CUSTOMER_ACTIVE',
        payload: {
            value: true,
        },
    })
}

export const closeCreateNewCustomer = () => {
    store.dispatch({
        type: 'SET_CREATE_NEW_CUSTOMER_ACTIVE',
        payload: {
            value: false,
        },
    })
}

export const showCancelNewCustomer = (onYesUrl) => {
    store.dispatch({
        type: 'SET_CANCEL_NEW_CUSTOMER_ACTIVE',
        payload: {
            value: true,
            onYesUrl: onYesUrl,
        },
    })
}

export const showHasSkodaIdentPopup = () => {
    store.dispatch({
        type: 'SET_HAS_SKODA_IDENT_ACTIVE',
        payload: {
            value: true,
        },
    })
}

export const closeHasSkodaIdentPopup = () => {
    store.dispatch({
        type: 'SET_HAS_SKODA_IDENT_ACTIVE',
        payload: {
            value: false,
        },
    })
}

export const closeCancelNewCustomer = () => {
    store.dispatch({
        type: 'SET_CANCEL_NEW_CUSTOMER_ACTIVE',
        payload: {
            value: false,
            onYesUrl: null,
        },
    })
}

export const onCancelNewCustomerConfirm = () => {
    store.dispatch({
        type: 'SET_CANCEL_NEW_CUSTOMER_ACTIVE',
        payload: {
            value: false,
            onYesUrl: null,
        },
    })
    store.dispatch({
        type: 'CLEAR_NEW_CUSTOMER',
    })
}

export const showDealerProfilePopup = () => {
    store.dispatch({
        type: 'SET_DEALER_POPUP_ACTIVE',
        payload: {
            value: true,
        },
    })
}

export const closeDealerProfilePopup = () => {
    store.dispatch({
        type: 'SET_DEALER_POPUP_ACTIVE',
        payload: {
            value: false,
        },
    })
}

export const showToast = () => {
    store.dispatch({
        type: 'SET_TOAST_ACTIVE',
        payload: {
            value: true,
        },
    })
}

export const closeToast = () => {
    store.dispatch({
        type: 'SET_TOAST_ACTIVE',
        payload: {
            value: false,
        },
    })
}
