import { DateTime } from 'luxon'

const initialState = {
    loading: false,
    error: false,
    taskList: [],
}

export const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SAVE_TASKS_STARTED':
            return {
                ...state,
                loading: true,
                error: false,
            }
        case 'SAVE_TASKS_SUCCESS':
            return {
                ...state,
                taskList: action.payload.tasks,
                loading: false,
                error: false,
            }
        case 'SAVE_TASKS_ERROR':
            return {
                ...state,
                taskList: [],
                loading: false,
                error: true,
            }
        case 'CREATE_NEW_CUSTOMER_TASK':
            const payload = action.payload.customer
            const today = new Date()
            const handover =
                payload.orderDetails.handoverDate !== null
                    ? payload.orderDetails.handoverDate
                    : payload.orderDetails.plannedProductionDate
            // TODO this may change
            const newTask = {
                commissionNumber: payload.orderDetails.commissionNumber,
                firstName: payload.customerInformation.firstName,
                lastName: payload.customerInformation.lastName,
                email: payload.customerInformation.email,
                fpin: null,
                vin: payload.orderDetails.vin,
                dateOfCreation: DateTime.utc(
                    today.getUTCFullYear(),
                    today.getUTCMonth() + 1,
                    today.getUTCDate()
                )
                    .toFormat('yyyy-MM-dd')
                    .toString(),
                // handoverDate: DateTime.utc(
                //     handover.getUTCFullYear(),
                //     handover.getUTCMonth() + 1,
                //     handover.getUTCDate()
                // )
                //     .toFormat('yyyy-MM-dd')
                //     .toString(),
                customerStatus: 'newCustomer',
            }
            const newTaskList = []
            state.taskList.forEach((t) => {
                newTaskList.push(t)
            })
            newTaskList.push(newTask)
            return {
                ...state,
                taskList: newTaskList,
            }
        default:
            return state
    }
}
