import PopUp from './PopUp'
import React from 'react'
import PrimaryButton from '../Buttons/PrimaryButton'
import warning from '../../assets/images/warning.svg'
import { useHistory } from 'react-router'
import {
    NAVIGATION_BUTTONS_CONFIGURATION,
    SKODA_IDENT_STEP,
} from '../../constants/RegistrationConstants'
import { closeHasSkodaIdentPopup } from '../../actions/popupsActions'

export default function CustomerHasSkodaIdentPopup({ active }) {
    const history = useHistory()

    const content = (
        <div className="dialog__content-wrapper">
            <img className="dialog__warning-icon" src={warning} width={56} alt="warning" />
            <div className="dialog__content-title">This customer has Skoda Ident</div>
        </div>
    )

    const onContinue = () => {
        closeHasSkodaIdentPopup()
        history.push({
            pathname: NAVIGATION_BUTTONS_CONFIGURATION[SKODA_IDENT_STEP].nextButtonUrl,
        })
    }

    const actions = (
        <div className="dialog__buttons-wrapper">
            <PrimaryButton clickAction={onContinue} label="Continue to Order Details" />
        </div>
    )

    const config = {
        title: 'Create New Customer',
        type: 'HAS_SKODA_IDENT',
        actions: actions,
        body: content,
    }
    return <PopUp open={active} body={config.body} title={config.title} actions={config.actions} />
}
