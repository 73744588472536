const initialState = {
    loading: false,
    error: false,
    salutations: [],
    addressTypes: [],
    countries: [],
    cultures: [],
    selectedLanguage: null,
    translations: {},
}

export const configurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_LANGUAGE':
            return {
                ...state,
                selectedLanguage: action.payload.value,
            }
        case 'GET_CONFIG_STARTED':
        case 'GET_CULTURES_STARTED':
        case 'GET_TRANSLATIONS_STARTED':
            return {
                ...state,
                loading: true,
            }
        case 'GET_CONFIG_SUCCESS':
            return {
                ...state,
                salutations: action.payload.config.salutations,
                addressTypes: action.payload.config.addressTypes,
                countries: action.payload.config.countries,
                loading: false,
                error: false,
            }
        case 'GET_CONFIG_ERROR':
        case 'GET_CULTURES_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
            }
        case 'GET_CULTURES_SUCCESS':
            return {
                ...state,
                cultures: action.payload.value,
            }
        case 'GET_TRANSLATIONS_SUCCESS':
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.payload.language]: action.payload.translations,
                },
            }
        case 'GET_ALL_TRANSLATIONS_SUCCESS':
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}
