import React, { Fragment, useCallback } from 'react'
import styled from 'styled-components'
import IconBack from '../../assets/images/icon-back.svg'
import { useHistory } from 'react-router-dom'
import back from '../../assets/images/icon-back.svg'

const Wrapper = styled.div`
    margin-bottom: 29px;
    cursor: pointer;
`

function BackLinkArrow({ backLinkUrl }) {
    const history = useHistory()

    const clickBackLink = useCallback(() => {
        history.push(backLinkUrl)
    }, [backLinkUrl, history])

    return (
        <Wrapper>
            <img
                className="button-back-icon"
                src={back}
                width={24}
                alt="back"
                onClick={clickBackLink}
            />
        </Wrapper>
    )
}

export default BackLinkArrow
