import React, { useEffect } from 'react'
import HorizontalStepper from '../../components/Registration/Stepper/HorizontalStepper'
import RegistrationNavigationButtons from '../../components/Registration/Navigation/RegistrationNavigationButtons'
import {
    CONSENT_VERIFICATION_STEP,
    NAVIGATION_BUTTONS_CONFIGURATION,
} from '../../constants/RegistrationConstants'
import FormWrapper from '../../components/Registration/Panel/FormWrapper'
import Panel from '../../components/Registration/Panel/Panel'
import { useDispatch, useSelector } from 'react-redux'
import { MuiThemeProvider } from 'material-ui'
import { SecondaryButton } from '../../components/Buttons/SecondaryButton'
import { PrintButton } from '../../components/Buttons/PrintButton'
import Checkbox from '../../components/Checkbox/Checkbox'
import { useHistory } from 'react-router'
import { showToast } from '../../actions/popupsActions'

export default function ConsentVerificationForm() {
    const registration = useSelector((state) => state.registration)
    const dispatch = useDispatch()
    const history = useHistory()

    const buttonConfig = NAVIGATION_BUTTONS_CONFIGURATION[CONSENT_VERIFICATION_STEP]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleBack = () => {
        history.push({
            pathname: buttonConfig.backButtonUrl,
        })
    }

    const handleChange = (field) => {
        dispatch({
            type: 'SAVE_CONSENT_VERIFICATION',
            payload: {
                name: field,
                value: !registration.consentVerification[field],
            },
        })
    }
    const onNextClick = () => {
        // TODO save for real
        dispatch({
            type: 'CREATE_NEW_CUSTOMER_TASK',
            payload: {
                customer: registration,
            },
        })
        showToast()
        history.push({
            pathname: '/customers',
        })
        dispatch({
            type: 'CLEAR_NEW_CUSTOMER',
        })
    }

    return (
        <MuiThemeProvider>
            <FormWrapper>
                <HorizontalStepper activeStep={CONSENT_VERIFICATION_STEP} />
                <Panel>
                    <div className="registration-step-title">Consent Verification</div>
                    <form noValidate autoComplete="off">
                        <div className="registration-form-row registration-form__text">
                            {
                                'If needed, print out the documents, have the customer sign them and store them in a safe place.'
                            }
                        </div>
                        <div className="registration-form-row registration-form__buttons">
                            <SecondaryButton clickAction={handleBack} label="Back" isBack />
                            <PrintButton label="Print documents" />
                        </div>
                        <div className="registration-form-row registration-form__checkbox-container">
                            <Checkbox
                                isChecked={registration.consentVerification.gdprAgreed}
                                onClick={() => handleChange('gdprAgreed')}
                                label="Customer consents to data processing."
                            />
                        </div>
                        <div className="registration-form__divider" />
                        <div className="registration-form-row registration-form__checkbox-container">
                            <Checkbox
                                isChecked={registration.consentVerification.activateServicesAgreed}
                                onClick={() => handleChange('activateServicesAgreed')}
                                label="Customer agrees with preparation to activate Skoda Connect Services."
                            />
                            <Checkbox
                                isChecked={registration.consentVerification.pspSetupAgreed}
                                onClick={() => handleChange('pspSetupAgreed')}
                                label="Customer agrees with Preferred Service Partner setup and activation of Service Scheduling."
                            />
                        </div>
                    </form>
                    <RegistrationNavigationButtons
                        activeStep={CONSENT_VERIFICATION_STEP}
                        onNextClick={onNextClick}
                    />
                </Panel>
            </FormWrapper>
        </MuiThemeProvider>
    )
}
