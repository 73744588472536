const initialState = {
    loading: false,
    error: false,
    customer: null,
    customerCheck: null,
}

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CUSTOMER_STARTED':
        case 'CHECK_CUSTOMER_STARTED':
            return {
                ...state,
                loading: true,
                error: false,
            }
        case 'GET_CUSTOMER_SUCCESS':
            return {
                ...state,
                customer: action.payload.customer,
                loading: false,
                error: false,
            }
        case 'GET_CUSTOMER_ERROR':
            return {
                ...state,
                customer: [],
                loading: false,
                error: true,
            }
        case 'CHECK_CUSTOMER_SUCCESS':
            return {
                ...state,
                customerCheck: action.payload.customerCheck,
                loading: false,
                error: false,
            }
        case 'CHECK_CUSTOMER_ERROR':
            return {
                ...state,
                customer: [],
                customerCheck: null,
                loading: false,
                error: true,
            }
        case 'REMOVE_CUSTOMER_CHECK':
            return {
                ...state,
                customerCheck: null,
            }
        default:
            return state
    }
}
