import TextInput from '../components/Form/TextInput'
import React, { Fragment, useCallback, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from '../components/Form/DatePicker'
import PrimaryButton from '../components/Buttons/PrimaryButton'
import {
    addressFieldsConfiguration,
    countriesList,
} from '../configuration/CountriesAddressBuilderConfiguration'
import { countryPhoneCodes } from '../configuration/CountryPhoneCodesConfiguration'
import { SecondaryButton } from '../components/Buttons/SecondaryButton'
import { SkipButton } from '../components/Buttons/SkipButton'
import { PrintButton } from '../components/Buttons/PrintButton'
import { CancelButton } from '../components/Buttons/CancelButton'

const Root = styled.div`
    padding: 0 20em;
`
const Row = styled.div`
    display: flex;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
`
const Cell = styled.div`
    margin-right: 16px;
    flex: 1 1 auto;
     {
        ${({ size }) =>
            size &&
            `
        flex: ${size} 1 auto;
    `}
    }
`

export default function CustomerInformation() {
    const customerInformation = useSelector((state) => state.registration.customerInformation)
    const errors = useSelector((state) => state.registration.customerInformation.errors)

    const dispatch = useDispatch()

    const handleChange = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_CUSTOMER_INFORMATION',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
    }

    const handleForm = useCallback(
        (type) => (e) => {
            dispatch({ type: type, payload: e.target.value })
        },
        [dispatch]
    )

    const handleChangeDate = useCallback(
        (key) => (event) => {
            if (event instanceof Date) {
                dispatch({
                    type: 'SAVE_CUSTOMER_INFORMATION',
                    payload: {
                        name: key,
                        value: event,
                    },
                })
            }
        },
        [dispatch]
    )

    const handleChangeSalutation = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_CUSTOMER_INFORMATION',
            payload: {
                name: event.target.name,
                value: { id: event.target.value },
            },
        })
    }

    const salutationOptions = ['Choose', 'Mr.', 'Mrs.']

    let countryOptions = countriesList.map((c) => c.name)
    countryOptions.unshift('Choose')

    let countryCodeOptions = countryPhoneCodes.map((c) => '(' + c.dial_code + ') ' + c.name)
    countryCodeOptions.unshift('Choose')

    const handleChangeCountry = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_CUSTOMER_INFORMATION',
            payload: {
                name: event.target.name,
                value: { id: event.target.value },
            },
        })
        const countryId = countriesList.find((c) => c.id === event.target.value).countryCode
        const config = addressFieldsConfiguration[countryId]

        dispatch({
            type: 'ADD_ADDRESS_FIELDS',
            payload: {
                value: config.fields,
            },
        })
    }

    return (
        <Root>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'email'}
                        label={`Email`}
                        value={customerInformation.email}
                        fullWidth
                        error={errors.email}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        inputId={'driverLicenseNumber'}
                        label={`Driver License Number`}
                        value={customerInformation.driverLicenseNumber}
                        fullWidth
                        error={errors.driverLicenseNumber}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChangeSalutation}
                        inputId={'salutation'}
                        label={'Salutation'}
                        value={customerInformation.salutation.id.toString()}
                        options={salutationOptions}
                        error={errors.salutation}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'firstName'}
                        label={'First Name'}
                        value={customerInformation.firstName}
                        error={errors.firstName}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'lastName'}
                        label={'Last Name'}
                        value={customerInformation.lastName}
                        error={errors.lastName}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'middleName'}
                        label={'Middle Name'}
                        value={customerInformation.middleName}
                        error={errors.middleName}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'nickName'}
                        label={'Nick Name'}
                        value={customerInformation.nickName}
                        error={errors.nickName}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChangeCountry}
                        inputId={'country'}
                        label={'Country'}
                        value={customerInformation.country.id.toString()}
                        options={countryOptions}
                        error={errors.country}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChangeCountry}
                        inputId={'street'}
                        label={'Street'}
                        value={customerInformation.street}
                        error={errors.street}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChangeCountry}
                        inputId={'houseNumber'}
                        label={'House number'}
                        value={customerInformation.houseNumber}
                        error={errors.houseNumber}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChangeCountry}
                        inputId={'city'}
                        label={'City'}
                        value={customerInformation.city}
                        error={errors.city}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChangeCountry}
                        inputId={'zip'}
                        label={'ZIP'}
                        value={customerInformation.zip}
                        error={errors.zip}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'dialCodeCountryId'}
                        label={'Country Code'}
                        value={customerInformation.dialCodeCountryId}
                        options={countryCodeOptions}
                        error={errors.dialCodeCountryId}
                    />
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <TextInput
                        handleChange={handleChange}
                        inputId={'phoneNumber'}
                        label={'Phone Number'}
                        value={customerInformation.phoneNumber}
                        error={errors.phoneNumber}
                    />
                </Cell>
            </Row>
            <Row>
                <PrimaryButton label={'Update information'} />
            </Row>
            <Row>
                <CancelButton label={'Delete customer'} />
            </Row>
        </Root>
    )
}
